import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import LineChart from '~reactComponents/charts/LineChart/LineChart.react';
import NoDataAvailableText from '~reactComponents/NoDataAvailableText/NoDataAvailableText.react';

import { LineChartData } from '~views/organization/efficiency/CumulativeCosts/Costs.types.react';
import { IRouter, IRoute, OptionType } from '~utils/global.types.react';

import { getCurrentTimezone } from '~reactHelpers';
import { strings } from '~utils/strings';
import { formatDatetime } from '~reactHelpers';
import { formatCosts } from '~reactHelpers';
import { colors } from '~views/organization/efficiency/Capacity/components/components.config.react';
import logger from '~utils/logger';
import { sendRequest } from '~utils/request';
import { CardTitlePrimary } from '~utils/texts.react';

import './Costs.react.scss';

dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);

type CostsProps = { route: IRoute; className: string; router: () => IRouter };

const Costs = ({ route, className, router }: CostsProps) => {
  const endDate = formatDatetime(dayjs());
  let startDate = formatDatetime(dayjs(endDate).subtract(1, 'months'));
  const dateTimeOptions: OptionType[] = [
    { value: strings.noTranslate.hourly, label: strings.dateOptions.lastDay },
    { value: strings.noTranslate.weekly, label: strings.dateOptions.lastWeek },
    { value: strings.noTranslate.monthly, label: strings.dateOptions.lastMonth },
  ];
  const org = route?.params?.org;
  const cluster = route?.params?.cluster;
  const timeZone = getCurrentTimezone();
  const [loading, setLoading] = useState<boolean>(false);
  const [period, setPeriod] = useState<OptionType>({
    value: strings.noTranslate.monthly,
    label: strings.dateOptions.lastMonth,
  });
  const [highestCost, setHighestCost] = useState<number>(0);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    setLoading(true);
    init();
  }, [period]);

  const init = async () => {
    if (period.value === strings.noTranslate.hourly) {
      startDate = formatDatetime(dayjs(endDate).subtract(1, 'days'));
    } else if (period.value === strings.noTranslate.weekly) {
      startDate = formatDatetime(dayjs(endDate).subtract(1, strings.weeks));
    }
    let baseURL = `/v0/organizations/${org}/resources-summary-timeseries?startDate=${startDate}&endDate=${endDate}&aggregators=namespace&clusters=${cluster}&period=${
      period.value === strings.noTranslate.hourly
        ? strings.noTranslate.hourly
        : strings.noTranslate.daily
    }`;

    baseURL += strings.noTranslate.addNetworkAndStorageTrue;

    let response;
    try {
      response = await sendRequest('GET', baseURL, {}, null);
    } catch (e) {
      logger.logError('error_retrieving_costs_cluster_overview', e);
    }

    setResources(response.resources);
    setLoading(false);
  };

  const formattedData = useMemo(() => {
    const finalData: LineChartData[] | [] = [];
    let highestAmount = 0;
    if (resources) {
      const linesToDisplay = resources.length > 15 ? resources.slice(0, 15) : [...resources];
      linesToDisplay.forEach((line, idx) => {
        const firstObject = line.timeseries[0];
        if (!firstObject) return;
        const lineData = {
          id: idx + 1,
          data: [],
        };
        finalData.push(lineData);
        lineData['namespace'] = firstObject['namespace'];

        line.timeseries.forEach((datum) => {
          lineData.data.push({
            x: new Date(Date.parse(datum.time)),
            y: datum.costs.actual.total,
          });
          if (datum.costs.actual.total > highestAmount) highestAmount = datum.costs.actual.total;
        });
      });
    }
    setHighestCost(highestAmount);
    return finalData.find((line) => line.data.length > 1) ? finalData : [];
  }, [resources]);

  const tooltip = (point: any) => {
    const matchedLine = formattedData.find((datum) => point.serieId === datum.id);

    return (
      <div className="cluster-costs-chart__tooltip">
        <strong>{point.data.xFormatted}:</strong>
        <span>{`  ${new Intl.NumberFormat(
          'en',
          highestCost < 0.55
            ? {
                style: 'currency',
                currency: 'USD',
                maximumSignificantDigits: 3,
              }
            : {
                style: 'currency',
                currency: 'USD',
              },
        ).format(point.data.y)}`}</span>
        <div>
          <strong className="cluster-costs-chart__subtitle">{`${strings.efficiency.Namespace}:`}</strong>
          <span>{matchedLine?.namespace || ''}</span>
        </div>
      </div>
    );
  };

  const lineChartProps = {
    data: formattedData,
    margin: { top: 5, right: 50, bottom: 55, left: 60 },
    xScale: {
      type: 'time',
      format: period.value === strings.noTranslate.hourly ? '%Y-%m-%d %H' : '%Y-%m-%d',
      useUTC: false,
      precision: period.value === strings.noTranslate.hourly ? 'hour' : 'day',
    },
    xFormat: period.value === strings.noTranslate.hourly ? 'time:%H' : 'time:%Y-%m-%d',
    yScale: {
      type: 'linear',
      min: 0,
      max: highestCost * 1.15,
      stacked: false,
      reverse: false,
    },
    axisBottom: {
      format: (value: Date) => {
        const formatter = timeZone ? dayjs(value).tz(timeZone) : dayjs(value);
        return period.value === strings.noTranslate.hourly
          ? formatter.format('M/D hA')
          : formatter.format('M/D');
      },
      tickValues:
        period.value === strings.noTranslate.hourly
          ? strings.noTranslate.every4Hours
          : 'every 1 days',
      tickRotation: -45,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -45,
      format: (value: number) => {
        if (Number.isInteger(value) || highestCost < 0.55 || value > highestCost) {
          return formatCosts(value);
        }
        return '';
      },
    },
    tooltip: tooltip,
    colors: colors,
  };

  return (
    <Card className={className}>
      <Card.Header className="cluster-costs__header">
        <h2
          className={clsx(
            'cluster-costs__title',
            CardTitlePrimary({ color: strings.textStyling.default }),
          )}
        >
          {strings.clusterOverview.costsByNamespace}
        </h2>
        <div
          className="cluster-costs date-select-container"
          data-cy="overview-costs-card__date-options"
        >
          <Select
            aria-label={strings.ariaLabels.costsByNamespaceSelect}
            className="cluster-costs date-select"
            classNamePrefix="cluster-costs date-select"
            isSearchable={false}
            options={dateTimeOptions}
            value={period}
            onChange={setPeriod}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <div
          className="cluster-costs-chart-container"
          aria-label={strings.ariaLabels.costsByNamespaceChart}
        >
          {loading && <LoadingSpinner />}
          {!loading && formattedData.length > 0 && <LineChart {...lineChartProps} />}
          {!loading && !formattedData.length && (
            <div className="cluster-costs__no-data">
              <span className="cluster-costs__no-data-text">
                <NoDataAvailableText linkPath="report-hub" router={router} />
              </span>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Costs;
