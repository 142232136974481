import { strings } from '~utils/strings';

export function getDocumentationLink(reportId: string): string {
  if (reportId === strings.reportHub.admission) {
    return 'https://insights.docs.fairwinds.com/features/admission-controller/';
  }
  if (reportId === 'prometheus-metrics') {
    return 'https://insights.docs.fairwinds.com/reports/resource-metrics';
  }
  if (reportId === 'awscosts') {
    return 'https://insights.docs.fairwinds.com/technical-details/reports/aws-costs/';
  }
  if (reportId === 'cloudcosts') {
    return 'https://insights.docs.fairwinds.com/technical-details/reports/cloud-costs/';
  }
  if (reportId === 'falco') {
    return 'https://insights.docs.fairwinds.com/technical-details/reports/falco/';
  }
  return `https://insights.docs.fairwinds.com/reports/${reportId}`;
}
