import React from 'react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import { strings } from '~utils/strings';

export const severities = [
  {
    score: 3,
    title: strings.rbac.Admin,
    variant: 'critical',
    description: strings.rbac.adminDesc,
  },
  {
    score: 2,
    title: strings.rbac.Write,
    variant: 'high',
    description: strings.rbac.writeDesc,
  },
  {
    score: 1,
    title: strings.rbac.Read,
    variant: 'none',
    description: strings.rbac.readOnly,
  },
];

export const TABLE_COLUMNS = [
  {
    Header: strings.rbac.Permissions,
    accessor: 'Severity',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const matchingSeverity = severities.find(
        (severity) => severity.score === value || (value <= 1 && severity.score === 1),
      );
      return (
        <PillBadge
          color={matchingSeverity?.variant || 'primary'}
          text={matchingSeverity?.title || ''}
        />
      );
    },
    minWidth: 25,
  },
  {
    Header: strings.rbac.roleName,
    accessor: 'Details.name',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => {
      return <span className="no-overflow">{value}</span>;
    },
    minWidth: 350,
  },
  {
    Header: strings.efficiency.Namespace,
    accessor: 'Details.namespace',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => {
      return <span>{value || 'All'}</span>;
    },
    minWidth: 250,
  },
  {
    Header: strings.rbac.Subjects,
    accessor: 'Subjects',
    className: 'no-overflow',
    Cell: ({ value }: { value: Record<string, string>[] }) => {
      return (
        <div>
          {!value
            ? 'None'
            : value.map((subject) => {
                return (
                  <>
                    <span>
                      {`${subject.Kind} ${subject.AccountNamespace}${
                        subject.AccountNamespace ? '/' : ''
                      }${subject.Name} ${
                        subject.BindingNamespace ? `in ${subject.BindingNamespace}` : ''
                      }`}
                    </span>
                    <br />
                  </>
                );
              })}
        </div>
      );
    },
    minWidth: 525,
  },
];
