import React from 'react';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import { OrdinalColorScaleConfig } from '@nivo/colors';

import { AxisType } from '../Charts.react.types';

type LargeBarChartProps = {
  data: BarDatum[];
  keys: string[];
  indexBy: string;
  colors: OrdinalColorScaleConfig;
  margin?: Record<string, number>;
  axisBottom?: AxisType;
  axisLeft?: AxisType;
  maxValue?: number;
  groupMode?: 'stacked' | 'grouped';
  enableLabel?: boolean;
  labelTextColor?: string;
  onClick?: (data: any) => void;
  tooltip: (data: any, label?: string) => JSX.Element;
};

const LargeBarChart = ({
  data,
  keys,
  indexBy,
  margin,
  colors,
  axisBottom,
  axisLeft,
  tooltip,
  maxValue,
  onClick,
  groupMode = 'stacked',
  labelTextColor = 'inherit',
  enableLabel = false,
}: LargeBarChartProps) => {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={margin}
      maxValue={maxValue || 'auto'}
      padding={0.3}
      colors={colors}
      colorBy="indexValue"
      axisTop={null}
      axisRight={null}
      axisBottom={axisBottom || null}
      axisLeft={axisLeft || null}
      enableLabel={enableLabel}
      labelTextColor={labelTextColor}
      enableGridX={false}
      enableGridY={false}
      groupMode={groupMode}
      onClick={onClick || undefined}
      tooltip={({ data, label }) => tooltip(data, label)}
      onMouseEnter={(_data: any, event: any) => {
        if (onClick) {
          event.target.style.opacity = 0.8;
          event.target.style.cursor = 'pointer';
        }
      }}
      onMouseLeave={(_data: any, event: any) => {
        if (onClick) event.target.style.opacity = 1;
      }}
    />
  );
};

export default LargeBarChart;
