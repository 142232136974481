import React, { useEffect, useState, useRef } from 'react';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { FixedSizeList } from 'react-window';
import clsx from 'clsx';

import CreateTicketModal from '~reactComponents/createTicketModal/CreateTicketModal.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import NoDataAvailableText from '~reactComponents/NoDataAvailableText/NoDataAvailableText.react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';

import { ActionItem, TicketModal } from '~views/organization/actionItems/ActionItems.types.react';
import { createCSVFile } from '~views/organization/actionItems/ActionItems.helpers.react';
import ActionItemModal from '~views/organization/actionItems/ActionItemsList/modals/ActionItemModal/ActionItemModal.react';

import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import ExportIcon from '~assetIcons/export.svg';
import TableIcon from '~assetIcons/table.svg';

import { Cluster, IRoute, IRouter } from '~utils/global.types.react';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~utils/logger';
import { XSText, MediumBasicText, XLBasicText, LinkText } from '~utils/texts.react';

import './IssuesThisWeek.react.scss';

type IssuesThisWeekProps = {
  cluster: Cluster;
  route: IRoute;
  router: () => IRouter;
  className?: string;
};

const IssuesThisWeek = ({ cluster, route, router, className }: IssuesThisWeekProps): JSX.Element => {
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;

  const isOwner = route?.params?.isOwner;
  const [actionItemResolved, setActionItemResolved] = useState<number>(0);
  const [actionItems, setActionItems] = useState<ActionItem[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedActionItem, setSelectedActionItem] = useState<ActionItem | undefined>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTicketModal, setTicketModal] = useState<TicketModal>({ show: false, list: false });

  const nResolvedItems = useRef<number>(0);

  useEffect(() => {
    getActionItems();
  }, [actionItemResolved]);

  const countResolvedItems = (actionItems: ActionItem[]) => {
    if (!actionItems?.length) {
      return;
    }
    let count = 0;
    actionItems.forEach((item: ActionItem) => {
      if (item.Resolution) {
        count++;
      }
    });
    nResolvedItems.current = count;
  };

  const transformActionItems = (actionItems: ActionItem[]) => {
    if (!actionItems?.length) {
      return;
    }
    return actionItems.filter((item: ActionItem) => !item.Resolution);
  };

  const getActionItems = async () => {
    setIsLoading(true);
    try {
      const response = await sendRequest(
        'GET',
        `${baseURL}/clusters/${cluster}/action-items?Fixed=false&FirstSeen=Past+Week`,
        { showSuccessAlert: false },
        null,
      );
      countResolvedItems(response);
      setActionItems(transformActionItems(response));
      setIsLoading(false);
    } catch (e) {
      logger.logError('error_retrieving_action_items_cluster_overview');
    }
  };

  const exportCSVFile = async () => {
    if (org) {
      createCSVFile(actionItems || [], org, '', 'Issues This Week');
    }
  };

  const getActionItemInfo = (actionItem: ActionItem): void => {
    setShowModal(true);
    setSelectedActionItem(actionItem);
  };

  const switchToTableView = () => {
    router().push({
      path: `/orgs/${org}/action-items`,
      query: {
        Fixed: [false],
        FirstSeen: ['Past Week'],
        Resolution: ['none'],
        from: [strings.noTranslate.clusterOverview],
      },
    });
  };

  const closeModal = () => {
    setTicketModal({ show: false, list: false });
  };

  return (
    <Card className={`${className} issues-this-week-container`}>
      {selectedActionItem && (
        <ActionItemModal
          actionItem={selectedActionItem}
          baseURL={baseURL}
          resolveItem={setActionItemResolved}
          route={route}
          router={router}
          setShowModal={setShowModal}
          setTicketModal={setTicketModal}
          show={showModal}
          isEditable={false}
          isOwner={isOwner}
        />
      )}
      {showTicketModal.show && (
        <CreateTicketModal
          closeModal={closeModal}
          items={[selectedActionItem] as ActionItem[]}
          listID={undefined}
          route={route}
          router={router}
          showModal={showTicketModal.show}
          isOwner={isOwner}
        />
      )}
      <div className="lists-header">
        <div className="header-left">
          <h2 className={clsx('header-title', XLBasicText())}>{strings.clusterOverview.issuesThisWeek}</h2>
          <span className={XSText({ color: strings.textStyling.primary, topMargin: 'sm' })}>
            <span>
              {actionItems?.length || 0} {strings.clusterOverview.total}, {nResolvedItems.current}{' '}
              {strings.clusterOverview.resolved}
            </span>
          </span>
        </div>
        <div className={clsx('header-right', XSText({ color: strings.textStyling.primary }))}>
          <DropdownButton
            title={<img src={HorizontalEllipsis} alt="open list menu" />}
            className="delete-list-btn"
            aria-haspopup
          >
            <Dropdown.Item key="list-item-1" className="export-item" onClick={() => exportCSVFile()}>
              <div className="dropdown-item__content">
                <img className="export-icon" src={ExportIcon} alt="export icon" />
                <span className={MediumBasicText({ weight: strings.textStyling.regular })}>
                  {strings.clusterOverview.export}
                </span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item key="list-item-5" onClick={() => switchToTableView()} className="table-view">
              <div className="dropdown-item__content">
                <img className="table-icon" src={TableIcon} alt="table icon" />
                <span>{strings.clusterOverview.tableView}</span>
              </div>
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      {isLoading && (
        <div className="no-items-row tr loading">
          <div className="td default-container">
            <LoadingSpinner />
          </div>
        </div>
      )}
      {!isLoading && actionItems && actionItems?.length > 0 && (
        <div role="table">
          <FixedSizeList
            height={233.6}
            itemCount={actionItems?.length || 0}
            itemData={actionItems}
            itemSize={50}
            width={'100%'}
          >
            {({ data, index, style }) => {
              return (
                <div className="action-item-row" style={style} tabIndex={0} role="row">
                  <div className="action-item-info" onClick={() => getActionItemInfo(data[index])} aria-haspopup>
                    <span role="cell" className="severity">
                      <PillBadge severity={data[index].Severity} />
                    </span>
                    <span
                      role="cell"
                      className={clsx(
                        data[index].Fixed || data[index].Resolution !== ''
                          ? 'action-item-title line-through'
                          : 'action-item-title',
                        LinkText({ size: 'sm' }),
                      )}
                    >
                      {`${data[index].Title} - ${data[index].ResourceKind} ${data[index].ResourceName}`}
                    </span>
                  </div>
                </div>
              );
            }}
          </FixedSizeList>
        </div>
      )}
      {!isLoading && !actionItems?.length && (
        <div className="no-items-row tr">
          <div className="td default-container no-items-text">
            <NoDataAvailableText linkPath="report-hub" router={router} />
          </div>
        </div>
      )}
    </Card>
  );
};

export default IssuesThisWeek;
