import { OptionType } from '~utils/global.types.react';

export const Labels = {
  topNewIssues: 'Top New Issues',
  pastWeek: 'Past Week',
} as const;

export const defaultDateTimeOptions: OptionType[] = [
  { value: 'Past Day', label: 'Last Day' },
  { value: 'Past Week', label: 'Last Week' },
  { value: 'Past Month', label: 'Last Month' },
];
