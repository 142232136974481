import React, { useEffect, useState } from 'react';

import { IRoute, IRouter } from '~globalTypes';

import { strings } from '~utils/strings';

import './ErrorPage.react.scss';

type ErrorMessage = {
  errorCode: string;
  message: string;
  subMessage: string;
};

type ErrorPageProps = {
  route: IRoute;
  router: () => IRouter;
};

const ErrorPage = ({ route, router }: ErrorPageProps) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
    errorCode: strings.errorPage.errorCode,
    message: strings.errorPage.message,
    subMessage: strings.errorPage.subMessage,
  });

  useEffect(() => {
    if (route?.query?.errorCode) {
      setErrorMessage({
        errorCode: route?.query?.errorCode || strings.errorPage.errorCode,
        message: route?.query?.message || strings.errorPage.message,
        subMessage: route?.query?.subMessage || strings.errorPage.subMessage,
      });
    }
  }, [route]);

  return (
    <div className="container">
      <div className="card-list">
        <div className="card">
          <div className="card-body">
            <div className="title-area text-center">
              <h1 className="display-2 text-danger font-weight-bold mb-2">
                {strings.errorPage.ohno}
              </h1>
            </div>
            <div className="row justify-content-center text-center">
              <div className="col-9">
                <h2 className="display-5 font-weight-bold text-center mt-2">
                  {errorMessage.message ? (
                    <span>{errorMessage.message}</span>
                  ) : (
                    <div>{strings.errorPage.unexpectedErrorOccurred}</div>
                  )}
                </h2>
                {errorMessage?.subMessage ? <p>{errorMessage.subMessage}</p> : null}
                <h3 className="display-5 text-center font-weight-bold">
                  {strings.errorPage.errorCodeText} {errorMessage.errorCode}
                </h3>
                <p className="text-center">{strings.errorPage.helpfulLinks}</p>
                <ul className="list-unstyled">
                  <li className="text-center">
                    <a href={router().resolve({ path: '/' }).href}>{strings.errorPage.home}</a>
                  </li>
                  <li className="text-center">
                    <a href="https://insights.docs.fairwinds.com" target="_blank">
                      {strings.errorPage.docs}
                    </a>
                  </li>
                  <li className="text-center">
                    <a href="https://www.fairwinds.com/fairwinds-insights-faq" target="_blank">
                      {strings.errorPage.faqs}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
