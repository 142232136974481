import React, { ReactElement } from 'react';
import { Alert } from 'react-bootstrap';
import clsx from 'clsx';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import ExclamationTriangleIcon from '~reactComponents/Icons/ExclamationTriangle.react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import ReportHubAbout from './about/About.react';
import ReportHubConfigure from './configure/Configure.react';
import ReportHubHistory from './history/History.react';

import {
  CLUSTERS,
  CLUSTER_OVERVIEW,
  CLUSTER_REPORT_HUB,
  ORG_DASHBOARD,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { ReportDetail } from './ReportHub.types.react';
import { IRoute, IRouter, IStore } from '~utils/global.types.react';

import { REMOVE_REPORT_CONFIGS, UPDATE_REPORTS_CONFIGS } from '~store/action.types';

import { getDocumentationLink } from './ReportHub.helpers.react';
import { getAllImages, REPORTS_DETAILS } from '~utils/constants';
import { handlePageChange, safeClickHandler } from '~utils/global.helpers.react';
import { strings } from '~utils/strings';

import './style.scss';

type REPORT_DETAILS_KEY = keyof typeof REPORTS_DETAILS;

interface ReportHubProps {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
}

export default function ReportHub({ store, route, router }: ReportHubProps) {
  const { reportsConfigs, reportsStatus, isOrgOwner } = store().getters;
  const { report: reportId, org, cluster } = route?.params || {};
  const report = REPORTS_DETAILS[reportId as REPORT_DETAILS_KEY] as ReportDetail;
  const reportSection = route.name?.replace('hub-report-', '') || '';
  const images = getAllImages();
  const reportImage = images[reportId] || images.default;

  function addOrRemoveReport(report: ReportDetail, removeReport: boolean) {
    if (removeReport) {
      store().dispatch(REMOVE_REPORT_CONFIGS, report.id);
    } else {
      store().dispatch(UPDATE_REPORTS_CONFIGS, { report: report.id });
    }
  }

  const SubPages: Record<string, ReactElement> = {
    about: <ReportHubAbout store={store} route={route} router={router} />,
    configure: <ReportHubConfigure store={store} route={route} router={router} />,
    history: <ReportHubHistory store={store} route={route} router={router} />,
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTERS,
      label: strings.navigation.allClusters,
      href: `/orgs/${org}/clusters`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster,
      href: `/orgs/${org}/clusters/${cluster}/overview`,
    },
    {
      id: CLUSTER_REPORT_HUB,
      label: 'Install Hub',
      href: `/orgs/${org}/clusters/${cluster}/report-hub`,
    },
    {
      id: 'last',
      label: report.name,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact className="report-hub">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {!isOrgOwner && (
        <Alert className="mt-2" variant="warning" show={true}>
          <div className="d-flex align-content-center">
            <ExclamationTriangleIcon />
            <span className="ml-2">Only owners are allowed to install and uninstall Agents.</span>
            <span>
              <b>This page is read-only.</b>
            </span>
          </div>
        </Alert>
      )}
      <div className="row">
        <div className="col-12">
          <section>
            <div className="row m-0">
              <div className="report-image">
                <img className="mr-2" src={reportImage} alt="report icon" />
                {isOrgOwner && (
                  <>
                    {reportsConfigs[report.id] && !report.required && (
                      <button
                        className="btn btn-sm btn-outline-primary mt-2"
                        type="button"
                        onClick={() => {
                          addOrRemoveReport(report, true);
                        }}
                      >
                        Remove Report
                      </button>
                    )}

                    {!reportsConfigs[report.id] && !report.required && (
                      <button
                        className="btn btn-sm btn-outline-primary mt-2"
                        type="button"
                        onClick={() => {
                          addOrRemoveReport(report, false);
                        }}
                      >
                        Add Report
                      </button>
                    )}
                    {!!reportsConfigs[report.id] && report.required && (
                      <button
                        className="btn btn-sm btn-outline-primary mt-2"
                        type="button"
                        disabled
                      >
                        Required
                      </button>
                    )}
                  </>
                )}
              </div>
              <div className="summary">
                {!!reportsStatus[report.id] && reportsStatus[report.id].Enabled && (
                  <p className="report-name h5">
                    <span className="mr-2">{report.name}</span>
                    <PillBadge text="Installed" color={'installed'} />
                  </p>
                )}

                {!reportsStatus[report.id].Enabled && reportsConfigs[report.id] && (
                  <p className="report-name h5">
                    <span className="mr-2">{report.name}</span>
                    <PillBadge text="Pending" color={'none'} />
                  </p>
                )}

                {!reportsStatus[report.id].Enabled && !reportsConfigs[report.id] && (
                  <p className="report-name h5">
                    <span className="mr-2">{report.name}</span>
                    <PillBadge text="Available" color={strings.pillBadge.passing} />
                  </p>
                )}
                <p className="mb-2">{report.description}</p>
                <a href={getDocumentationLink(reportId)} target="_blank">
                  Read Documentation
                </a>
              </div>
            </div>
          </section>
          <nav
            className="horizontal-nav"
            role="navigation"
            aria-label="`${report.name} report navigation`"
            data-cy="report-horizontal-nav"
          >
            <ul>
              {[strings.reportHub.About, strings.reportHub.Configure, strings.reportHub.History]
                .filter((section) => !(!isOrgOwner && section === strings.reportHub.History))
                .map((section) => (
                  <li className="nav-item">
                    <a
                      className={clsx('nav-link', section, reportSection, {
                        active: section.toLowerCase() === reportSection,
                      })}
                      href={`/orgs/${org}/clusters/${cluster}/report-hub/report/${report.name.toLowerCase()}/${section.toLowerCase()}`}
                      onClick={safeClickHandler(() => {
                        handlePageChange(router, `hub-report-${section.toLowerCase()}`);
                      })}
                    >
                      {section}
                    </a>
                  </li>
                ))}
            </ul>
          </nav>
          {SubPages[reportSection]}
        </div>
      </div>
    </LayoutReact>
  );
}
