import ReactRBAC from './ReactRBAC.react';

export default {
  components: {
    ReactRBAC,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
