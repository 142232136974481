import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import clsx from 'clsx';

import { IReportEventSet, IReportEvent, IRoute, IRouter, IStore } from '~utils/global.types.react';

import { formatTime, formatTimeAgo, formatTimestampToDate } from './History.utils.react';
import { ReportTypeLabels } from '~utils/constants';
import { sendRequest } from '~utils/request';
import { getCurrentTimezone, handlePageChange, safeClickHandler } from '~reactHelpers';
import { strings } from '~utils/strings';
import { XLBasicText, BasicText } from '~utils/texts.react';

import './style.scss';

interface ReportHubHistoryProps {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
}

export default function ReportHubHistory({ route, router, store }: ReportHubHistoryProps) {
  const { report } = route?.params || {};
  const { reportsStatus, organization, cluster } = store().getters;
  const reportLabel = ReportTypeLabels[report];
  const isInstalled = reportsStatus[report] && reportsStatus[report].LastSeen;
  const timeZone = getCurrentTimezone();

  // TODO: FWI-4703: Consider using a native Details element for this functionality
  // https://fairwinds.myjetbrains.com/youtrack/issue/FWI-4703/Install-Hub-Report-History-Refactor-clickable-divs-into-Detail-elements
  const [activeEventsMap, setActiveEventsMap] = useState<Record<string, Record<string, boolean>>>(
    {},
  );
  const [activeDuplicatesMap, setActiveDuplicatesMap] = useState<Record<string, boolean>>({});

  const [events, setEvents] = useState<IReportEventSet[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  function getDataURL(timestamp: string, failure: boolean): string {
    // timestamp is the filename of the report and can't be formatted/tz scoped
    let latestReportUrl = `/v0/organizations/${organization.Name}/clusters/${cluster.Name}/reports/${report}`;
    if (failure) {
      latestReportUrl = `${latestReportUrl}/failure/${timestamp}`;
    } else if (timestamp) {
      latestReportUrl = `${latestReportUrl}/detail?timestamp=${timestamp}`;
    } else {
      latestReportUrl = `${latestReportUrl}/detail`;
    }
    return latestReportUrl;
  }

  async function refreshData() {
    const condensedEvents: Record<string, IReportEventSet> = {};
    const eventsToRemove: string[] = [];

    if (!isInstalled) {
      return;
    }
    setIsLoading(true);
    const timestamp = null;
    const url = `/v0/organizations/${organization.Name}/clusters/${cluster.Name}/reports/${report}/history`;
    const events = await sendRequest('GET', url, {}, null);

    for (let i = 0; i < events.length; i += 1) {
      events[i].Events = events[i].Events.map((eventSet: IReportEvent) => ({
        active: false,
        ...eventSet,
      }));

      if (events[i]?.Events[0]?.EventType === 'report_failure') {
        const date = formatTimestampToDate(events[i].Timestamp, timeZone);
        if (!condensedEvents[date]) {
          condensedEvents[date] = events[i];
        } else {
          condensedEvents[date].Events = condensedEvents[date].Events.concat(events[i].Events);
          eventsToRemove.push(events[i].Timestamp);
        }
      }
    }

    eventsToRemove.forEach((toRemoveEvent) => {
      const removeEventIndex = events.findIndex(
        (event: IReportEventSet) => event.Timestamp === toRemoveEvent,
      );
      events.splice(removeEventIndex, 1);
    });

    setEvents(events.filter((event: IReportEventSet) => event.Events.length));
    setIsLoading(false);
  }

  function toggleEventSetEventVisibility(eventSetTimestamp: string, eventUpdateTime: string) {
    const newEventMap = activeEventsMap[eventSetTimestamp] || {};
    newEventMap[eventUpdateTime] = !newEventMap[eventUpdateTime];

    setActiveEventsMap({
      ...activeEventsMap,
      [eventSetTimestamp]: newEventMap,
    });
  }

  return (
    <div className="install-hub-report-history">
      {isInstalled && (
        <div className="container-fluid pl-0 mt-4">
          {report === strings.reportHub.admission && (
            <>
              <h2 className={XLBasicText({ bottomMargin: strings.textStyling.medBottom })}>
                History
              </h2>
              <a
                className="font-weight-bold"
                href={`/orgs/${organization.Name}/clusters/${cluster.Name}/admission`}
                onClick={safeClickHandler(() => {
                  handlePageChange(router, 'clusteradmission-controller');
                })}
              >
                See timeline of the Admission Controller
              </a>
            </>
          )}
          {isLoading && (
            <div className="history-spinner-container">
              <Spinner className="history-spinner" animation="border" variant="primary"></Spinner>
            </div>
          )}
          {report !== strings.reportHub.admission && !!events?.length && (
            <>
              {events.map((eventSet) => {
                const firstEventType = eventSet?.Events[0]?.EventType || '';

                return (
                  <div className="card" key={eventSet.Timestamp}>
                    {firstEventType !== 'report_failure' && (
                      <div className="card-body regular">
                        <p
                          className={XLBasicText({
                            weight: strings.textStyling.thin,
                            bottomMargin: 'bottomLG',
                          })}
                        >
                          <span>{formatTime(eventSet.Timestamp, timeZone)}</span>
                          <br />
                          <span
                            className={BasicText({
                              size: strings.textStyling.md,
                              weight: strings.textStyling.thin,
                              textTransform: 'italic',
                            })}
                          >
                            {formatTimeAgo(eventSet.Timestamp, timeZone)}
                          </span>
                          <small className="float-right">
                            <a href={getDataURL(eventSet.Timestamp, false)} target="_blank">
                              View Report
                            </a>
                          </small>
                        </p>
                        <div className="expandable-table">
                          {eventSet.Events.map((event: IReportEvent, index) => (
                            <div
                              key={event.Message}
                              className={clsx('expandable-item', {
                                expanded: activeEventsMap[eventSet.Timestamp]?.[event.UpdateTime],
                              })}
                            >
                              <div
                                className={BasicText({
                                  size: strings.textStyling.md,
                                  weight: strings.textStyling.regular,
                                })}
                                onClick={() => {
                                  toggleEventSetEventVisibility(
                                    eventSet.Timestamp,
                                    event.UpdateTime,
                                  );
                                }}
                              >
                                <i
                                  className={clsx('fa mr-2', {
                                    'fa-caret-right':
                                      !activeEventsMap[eventSet.Timestamp]?.[event.UpdateTime],
                                    'fa-caret-down':
                                      activeEventsMap[eventSet.Timestamp]?.[event.UpdateTime],
                                  })}
                                  aria-hidden="true"
                                ></i>
                                <span>{event.Message}</span>
                                <SeverityBadge severity={event.Severity} />
                              </div>
                              {activeEventsMap[eventSet.Timestamp]?.[event.UpdateTime] && (
                                <div className="details">
                                  <p>
                                    <b>Severity: </b>
                                    <span>{event.Severity}</span>
                                  </p>
                                  <p>
                                    <b>Details: </b>
                                    <pre>
                                      <code>
                                        <span>{JSON.stringify(event.EventDetails)}</span>
                                      </code>
                                    </pre>
                                  </p>
                                </div>
                              )}
                            </div>
                          ))}
                          {eventSet.Duplicates.length > 0 && (
                            <div
                              className={clsx('expandable-item', {
                                expanded: activeDuplicatesMap[eventSet.Timestamp],
                              })}
                            >
                              <div
                                className={BasicText({
                                  size: strings.textStyling.md,
                                  weight: strings.textStyling.regular,
                                })}
                                onClick={() => {
                                  setActiveDuplicatesMap({
                                    ...activeDuplicatesMap,
                                    [eventSet.Timestamp]: !activeDuplicatesMap[eventSet.Timestamp],
                                  });
                                }}
                              >
                                <i
                                  className={clsx('fa mr-2', {
                                    'fa-caret-right': !activeDuplicatesMap[eventSet.Timestamp],
                                    'fa-caret-down': activeDuplicatesMap[eventSet.Timestamp],
                                  })}
                                  aria-hidden="true"
                                ></i>
                                <span>
                                  {eventSet.Duplicates.length} subsequent reports with no change
                                </span>
                              </div>
                              {activeDuplicatesMap[eventSet.Timestamp] && (
                                <div className="details">
                                  <ul className="duplicates">
                                    {eventSet.Duplicates.map((dupeTime) => (
                                      <li className="duplicate" key={dupeTime}>
                                        <a href={getDataURL(dupeTime, false)} target="_blank">
                                          <span>{formatTime(dupeTime, timeZone)}</span>
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {!(eventSet.Events[0] && eventSet.Events[0].EventType !== 'report_failure') &&
                      eventSet.Events.length > 0 && (
                        <div className="card-body failed-reports">
                          <p
                            className={XLBasicText({
                              weight: strings.textStyling.thin,
                              bottomMargin: 'bottomLG',
                            })}
                          >
                            <span>
                              Failed Reports on{' '}
                              {formatTimestampToDate(eventSet.Timestamp, timeZone)}
                            </span>
                            <br />
                            <span
                              className={BasicText({
                                size: strings.textStyling.md,
                                weight: strings.textStyling.thin,
                                textTransform: 'italic',
                              })}
                            >
                              {formatTimeAgo(eventSet.Timestamp, timeZone)}
                            </span>
                            <div className="expandable-table">
                              {eventSet.Events.map((event: IReportEvent) => (
                                <div
                                  key={event.UpdateTime}
                                  className={clsx('expandable-item', {
                                    expanded:
                                      activeEventsMap[eventSet.Timestamp]?.[event.UpdateTime],
                                  })}
                                >
                                  <div
                                    className={BasicText({
                                      size: strings.textStyling.md,
                                      weight: strings.textStyling.regular,
                                    })}
                                    onClick={() => {
                                      toggleEventSetEventVisibility(
                                        eventSet.Timestamp,
                                        event.UpdateTime,
                                      );
                                    }}
                                  >
                                    <i
                                      className={clsx('fa mr-2', {
                                        'fa-caret-right':
                                          !activeEventsMap[eventSet.Timestamp]?.[event.UpdateTime],
                                        'fa-caret-down':
                                          activeEventsMap[eventSet.Timestamp]?.[event.UpdateTime],
                                      })}
                                      aria-hidden="true"
                                    ></i>
                                    <span>
                                      {event.Message} on {formatTime(event.UpdateTime, timeZone)}
                                    </span>
                                    <SeverityBadge severity={event.Severity} />
                                  </div>
                                  <div className="details">
                                    <p>
                                      <b>Severity: </b>
                                      <span>{event.Severity}</span>
                                    </p>
                                    <p>
                                      <b>Details: </b>
                                      <pre>
                                        <code>
                                          <span>{JSON.stringify(event.EventDetails)}</span>
                                        </code>
                                      </pre>
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </p>
                        </div>
                      )}
                    {!events?.length && (
                      <div className="card-body">
                        <p className={XLBasicText({ weight: strings.textStyling.thin })}>
                          <span>No history of {reportLabel} reports to display</span>
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
      {!isInstalled && (
        <div className="container-fluid pl-0 pt-5">
          <p className="h5">
            No reports found yet. Please enable this report and install or update the Insights
            Agent.
          </p>
        </div>
      )}
    </div>
  );
}

interface SeverityBadgeProps {
  severity: number;
}

function SeverityBadge({ severity }: SeverityBadgeProps) {
  let icon = <i className="text-danger fa fa-fw fa-times" v-else="v-else"></i>;

  if (severity < 0) {
    icon = <i className="text-success fa fa-fw fa-check"></i>;
  } else if (severity === 0) {
    icon = <i className="text-info fa fa-fw fa-question"></i>;
  } else if (severity < 0.33) {
    icon = <i className="text-warning fa fa-fw fa-exclamation"></i>;
  }

  return <div className="status float-right">{icon}</div>;
}
