import { mapGetters } from 'vuex';
import { DemoOrgName } from '@/utils/constants';

export default {
  props: {
    org: {
      type: String,
      required: true,
    },
    cluster: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: `${this.isDemoEnvironment ? 'Fairwinds Insights | Demo Environment' : 'Fairwinds Insights | Cluster'}`,
      meta: [
        {
          name: 'description',
          content: `${this.isDemoEnvironment ? 'Check out the Fairwinds Insights user interface using artificial data' : ''}`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['organization', 'organizationTierAccess']),
    isDemoEnvironment() {
      return (this.org === DemoOrgName);
    },
  },
};
