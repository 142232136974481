import React, { useEffect, useState } from 'react';
import { BarDatum } from '@nivo/bar';
import { toast } from 'react-hot-toast';
import Select from 'react-select';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import BarChart from '~reactComponents/charts/BarChart/BarChart.react';

import { defaultDateTimeOptions, Labels } from './TopIssuesClusterOverview.types.react';
import { Cluster, IRouter, OptionType, Organization } from '~utils/global.types.react';

import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import { COLORS } from '~utils/styling';
import logger from '~logger';

import './TopIssuesClusterOverview.react.scss';
import NoDataAvailableText from '~reactComponents/NoDataAvailableText/NoDataAvailableText.react';

type TopIssuesClusterOverviewProps = {
  cluster: Cluster;
  org: Organization;
  router: () => IRouter;
  className?: string;
};

const TopIssuesClusterOverview = ({
  cluster,
  org,
  router,
  className,
}: TopIssuesClusterOverviewProps) => {
  const [topIssues, setTopIssues] = useState<BarDatum[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [dateTimeOptions, setDateTimeOptions] = useState<OptionType[]>(defaultDateTimeOptions);
  const [dateTimeOption, setDateTimeOption] = useState<OptionType | null>(
    defaultDateTimeOptions.find((option: OptionType) => option.value === Labels.pastWeek) || null,
  );

  const baseURL = `/v0/organizations/${org}`;

  useEffect(() => {
    getTopIssues();
  }, [dateTimeOption]);

  const getTopIssues = async () => {
    setLoaded(false);

    const params = new URLSearchParams(`groupBy=title`);

    if (dateTimeOption) {
      params.set('FirstSeen', dateTimeOption.value);
    }

    try {
      const data: BarDatum[] = await sendRequest(
        'GET',
        `${baseURL}/clusters/${cluster}/action-items/top?${params}`,
        {},
        null,
      );
      setTopIssues(data?.length ? data.slice(0, 5).reverse() : []);
      setLoaded(true);
    } catch (e) {
      setLoaded(true);
      console.error('Error retrieving top issues - cluster overview: ', e);
      logger.logError('error_retrieving_top_issues_cluster_overview: ', e);
      toast.error(strings.clusterOverview.noClusterTopIssues);
    }
  };

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    router().push({
      path: `/orgs/${org}/action-items/?page=0&orderBy=Severity.desc&pageSize=25&Cluster=${cluster}&Title=${indexValue}&FirstSeen=${dateTimeOption?.value}&from=cluster-overview'`,
    });
  };

  const onDateTimeSelected = (selected: OptionType) => {
    setDateTimeOption(selected);
  };

  return (
    <>
      <Card className={`${className} top-issues-co__card`}>
        <Card.Header style={{ padding: '0 1rem', borderBottom: COLORS.BORDER.TABLE_HEADER }}>
          <h2>{Labels.topNewIssues}</h2>
          <div className="top-issues-co date-select-container">
            <Select
              aria-label={strings.ariaLabels.topClusterIssueDropdown}
              className="top-issues-co date-select"
              classNamePrefix="top-issues-co date-select"
              isSearchable={false}
              options={dateTimeOptions}
              value={dateTimeOption}
              onChange={onDateTimeSelected}
            />
          </div>
        </Card.Header>
        <Card.Body className="action--items--charts__graph" padded>
          {loaded ? (
            <>
              {topIssues?.length ? (
                <div
                  aria-label={strings.ariaLabels.topClusterIssuesChart}
                  className="action-item--charts"
                >
                  <BarChart
                    data={topIssues}
                    keys={['Count']}
                    indexBy="Title"
                    colors={[
                      COLORS.CHARTS.PURPLE[100],
                      COLORS.CHARTS.PURPLE[200],
                      COLORS.CHARTS.PURPLE[300],
                      COLORS.CHARTS.PURPLE[400],
                      COLORS.CHARTS.PURPLE[500],
                    ]}
                    layout="horizontal"
                    onClick={handleChartClick}
                    tooltipLabel={(d) => `${d?.data?.Title}`}
                    showLabel={true}
                  />
                </div>
              ) : (
                <div className="action--items--charts-empty">
                  <div className="text">
                    <NoDataAvailableText linkPath="report-hub" router={router} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default TopIssuesClusterOverview;
