import React, { useMemo } from 'react';
import { useTable, useSortBy, useResizeColumns, useFlexLayout, usePagination } from 'react-table';

import { Card } from '@fairwindsops/ui-components';
import CloseIcon from '~reactComponents/Icons/Close.icon.react';
import Table from '~reactComponents/Table/Table.react';

import { TABLE_COLUMNS } from './DescriptionPanel.config.react';
import { DescriptionPanelProps } from '../ReactRBAC.types.react';

import { strings } from '~utils/strings';

import './DescriptionPanel.scss';

const DescriptionPanel = ({ selectedItem = [], setSelectedItem, title }: DescriptionPanelProps) => {
  const columns = useMemo(() => TABLE_COLUMNS, [selectedItem]);
  const data = useMemo(() => selectedItem || [], [selectedItem]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 200,
      },
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
  );

  return (
    <Card
      className={`rbac--description description-panel ${
        selectedItem.length ? 'custom-card' : `rbac--description__hidden description-panel__hidden`
      }`}
    >
      <Card.Body padded>
        <Card.Header className="rbac-description-header no-overflow">
          <h1 className="rbac-description-item-title">{title}</h1>
          <CloseIcon
            width="0.75rem"
            height="0.75rem"
            onClick={() => setSelectedItem(null)}
            className="description-panel__close"
          />
        </Card.Header>
        <Table tableInstance={tableInstance} caption={strings.rbac.caption} />
      </Card.Body>
    </Card>
  );
};

export default DescriptionPanel;
