import React from 'react';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';
import UserAvatarIcon from '~reactComponents/Icons/UserAvatar.icon.react';

import { Data } from './ItemList.types.react';

import { strings } from '~utils/strings';
import { CardTitleDefault, XSCardTitle } from '~utils/texts.react';

import './ItemList.react.scss';

type ItemListProps = {
  title?: string;
  data?: Data[];
};

const ItemList = ({ title, data }: ItemListProps) => {
  if (!data?.length) {
    return <></>;
  }

  return (
    <Card>
      <Card.Body className="data-list__card-body">
        <Card.Title>
          <h2
            className={clsx(
              'data-list__title',
              CardTitleDefault({ weight: strings.textStyling.regular }),
            )}
          >
            {title}
          </h2>
        </Card.Title>
        {data.map((item: Data, index: number) => (
          <div key={index} className="data-list__item">
            {item.image ? (
              <img className="data-list__image" src={item.image} alt={item.heading} />
            ) : (
              <UserAvatarIcon className="data-list__image" />
            )}
            <div className="data-list__no-overflow">
              <p
                className={clsx(
                  'data-list__no-overflow',
                  XSCardTitle({ weight: strings.textStyling.medium }),
                )}
                title={item.heading}
              >
                {item.heading}
              </p>
              <p className={XSCardTitle()} title={item.subheading}>
                {item.subheading}
              </p>
            </div>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default ItemList;
