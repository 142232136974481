import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner.react';
import DonutChart from '~reactComponents/charts/DonutChart/DonutChart.react';

import {
  ColorScheme,
  HealthScoreChartData,
  HealthScoreSummary,
} from './HealthScoreCard.types.react';
import { Organization, Cluster, ClusterHealth, ClusterHealths, IRouter } from '~globalTypes';

import { CLUSTER_REPORT_HUB } from '~reactComponents/NavigationReact/Navigation.config.react';

import { getGrade } from '~utils/helpers';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~logger';
import { colorScheme } from '~config/main.config';
import { CardTitleDefault, Title, XSCardTitle } from '~utils/texts.react';

import './HealthScoreCard.react.scss';

type HealthScoreProps = {
  cluster?: Cluster;
  isInstallAgentShown?: boolean;
  organization: Organization;
  data: ClusterHealths | undefined;
  router: () => IRouter;
};

const HealthScoreCard = ({
  cluster,
  isInstallAgentShown,
  organization,
  data,
  router,
}: HealthScoreProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [healthScore, setHealthScore] = useState<number>();
  const [healthScoreSummary, setHealthScoreSummary] = useState<HealthScoreSummary>();
  const [previousHealthScoreSummary, setPreviousHealthScoreSummary] =
    useState<HealthScoreSummary>();

  useEffect(() => {
    initHealthScore(data);
  }, [cluster, data]);

  const initHealthScore = async (data: ClusterHealths | undefined) => {
    setLoading(true);
    const healthScoreRes = data || (await getHealthScore());
    initCurrentAndPreviousHealthScore(healthScoreRes);
  };

  const getHealthScore = async () => {
    const baseURL = cluster
      ? `/v0/organizations/${organization}/clusters/${cluster}`
      : `/v0/organizations/${organization.Name}`;
    try {
      return await sendRequest(
        'GET',
        `${baseURL}/health-score/summary`,
        { showSuccessAlert: false, cache: true },
        null,
      );
    } catch (e: any) {
      logger.logError('event_get_cluster_health_score', { message: e.message });
    }
  };
  const initCurrentAndPreviousHealthScore = (healthScoreRes: ClusterHealths | undefined) => {
    if (healthScoreRes?.Current) {
      setCurrentHealthScore(healthScoreRes.Current);
    }
    if (healthScoreRes?.Previous) {
      setPreviousHealthScore(healthScoreRes.Previous);
    }
  };

  const setCurrentHealthScore = (currentHealthScore: ClusterHealth) => {
    if (currentHealthScore) {
      const { HealthScore, Warning, Danger, ...HealthScoreSummary } = currentHealthScore;
      setHealthScoreSummary(() => HealthScoreSummary);
      setHealthScore(() => HealthScore);
    }
  };

  const setPreviousHealthScore = (previousHealthScore: ClusterHealth) => {
    if (previousHealthScore) {
      const { HealthScore, Warning, ...PreviousHealthScore } = previousHealthScore;
      setPreviousHealthScoreSummary(() => PreviousHealthScore);
    }
  };

  const getHealthScoreChartData = (): HealthScoreChartData[] => {
    if (typeof healthScore !== 'number' || !healthScoreSummary) return [];
    const chartData: HealthScoreChartData[] = [];
    for (const [key, value] of Object.entries(healthScoreSummary)) {
      chartData.push(getChartDataObj(key, value));
    }
    return chartData;
  };

  const getChartDataObj = (key: string, value: number): HealthScoreChartData => ({
    id: key,
    label: key,
    value,
    color: colorScheme[key.toLowerCase() as keyof ColorScheme],
  });

  const healthGrade = useMemo(() => {
    return getGrade(healthScore);
  }, [healthScore]);

  const healthScoreChartData = useMemo(() => {
    setLoading(false);
    return typeof healthScore === 'number' && healthScoreSummary ? getHealthScoreChartData() : [];
  }, [healthScore, healthScoreSummary]);

  const shouldHealthScoreCardShow = useMemo(() => {
    return healthScoreSummary && previousHealthScoreSummary && healthScoreChartData?.length;
  }, [healthScoreSummary, previousHealthScoreSummary, healthScoreChartData]);

  const HealthScoreLoader = () => (
    <div className="overview-healthscore-card__loading-container">
      <LoadingSpinner />
    </div>
  );

  const EmptyHealthScore = () => (
    <div className="overview-health-score-card-empty">
      <h2
        className={clsx(
          'sr-only',
          CardTitleDefault({
            weight: strings.textStyling.regular,
            textTransform: strings.textStyling.capitalize,
          }),
        )}
      >
        {strings.healthScore}
      </h2>
      <h3 className="overview-health-score-card-empty__title">{strings.general.noDataAvailable}</h3>
      <p className="overview-health-score-card-empty__description">{strings.healthScoreEmpty}</p>
      {isInstallAgentShown && (
        <a href={router().resolve({ name: CLUSTER_REPORT_HUB }).href} className="btn btn-primary">
          {strings.general.installAgent}
        </a>
      )}
    </div>
  );

  const getBodyClassName = () =>
    shouldHealthScoreCardShow
      ? 'overview-healthscore-card__body overview-healthscore-card__body-overview'
      : 'overview-healthscore-card__body--empty';

  return (
    <Card className="overview-healthscore-card" data-cy="healthscore-card">
      <Card.Body className={getBodyClassName()} aria-hidden="true" padded>
        <>
          {loading && <HealthScoreLoader />}
          {!loading && shouldHealthScoreCardShow ? (
            <>
              <div id="cluster-overview-health-score-pie-chart">
                <DonutChart
                  data={healthScoreChartData || []}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                  label={strings.ariaLabels.healthScoreDonutChart.replace(
                    '$healthGrade',
                    healthGrade,
                  )}
                  centerInfo={healthGrade}
                  centerInfoClassName={clsx(
                    'overview-healthscore-card__score',
                    Title({ size: strings.textStyling.md, weight: strings.textStyling.bold }),
                  )}
                />
              </div>
              <span className={XSCardTitle({ color: 'darkGray' })}>
                {strings.healthScoreDescription}
              </span>
            </>
          ) : (
            <></>
          )}
          {!loading && !shouldHealthScoreCardShow ? <EmptyHealthScore /> : <></>}
        </>
      </Card.Body>
    </Card>
  );
};

export default HealthScoreCard;
