import React, { useMemo } from 'react';
import { Card } from '@fairwindsops/ui-components';
import clsx from 'clsx';

import { Organization, Cluster, IRouter, ClusterSummary } from '~utils/global.types.react';

import { strings } from '~utils/strings';
import { CardTitleDefault, LinkText, SmallCardTitle, XSCardTitle } from '~utils/texts.react';

import './clusterVersion.react.scss';

type ClusterVersionProps = {
  organization: Organization;
  cluster: Cluster;
  clusterSummary: ClusterSummary;
  router: () => IRouter;
};

const ClusterVersion = ({ organization, cluster, clusterSummary, router }: ClusterVersionProps) => {
  const clusterBreakdown = useMemo(() => {
    const { Cluster, Summary, ChartVersionOK } = clusterSummary;
    const currAgentVersion = Cluster.AgentChartVersion || 'Unknown';

    const isAgentUpToDate = ChartVersionOK ? 'up-to-date' : 'out-of-date';

    return {
      'Agent Version': currAgentVersion,
      'Agent Status': isAgentUpToDate,
      Namespaces: Summary.Namespaces,
      Nodes: Summary.Nodes,
      Pods: Summary.Pods,
      'K8s Version': Summary.Version,
    };
  }, [cluster, clusterSummary]);

  return (
    <Card className="overview-cluster-version">
      <Card.Header className={clsx('overview-cluster-version__main-title', CardTitleDefault())}>
        {strings.clusterOverview.clusterInfo}
        {clusterBreakdown['Agent Status'] === 'out-of-date' && (
          <a
            href={
              router().resolve({
                name: 'clusterhub-install',
                params: { org: organization, cluster: cluster },
              }).href
            }
            className={LinkText()}
          >
            {strings.updateAgent}
          </a>
        )}
      </Card.Header>
      <Card.Body className="overview-cluster-version__body">
        {Object.keys(clusterBreakdown).map((status, index) => (
          <div className="overview-cluster-version-breakdown" key={`cluster-version-${index}`}>
            <span className={SmallCardTitle()}>{clusterBreakdown[status]}</span>
            <span className={clsx('overview-cluster-version-breakdown__name', XSCardTitle())}>
              {status}
            </span>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default ClusterVersion;
