import React from 'react';
import { Item, Menu } from 'react-contexify';

import ExportIcon from '~assetIcons/export.svg';

import { strings } from '~utils/strings';
import { ADDONS_PAGE_CONTEXT_MENU_ID } from '~reactHelpers';

import 'react-contexify/dist/ReactContexify.css';
import './ContextMenu.react.scss';

interface ItemProps {
  id: number;
}

type ContextMenuProps = {
  createCSV: () => void;
};

const ContextMenu = ({ createCSV }: ContextMenuProps) => {
  return (
    <Menu id={ADDONS_PAGE_CONTEXT_MENU_ID} className="context--menu" animation={false}>
      <Item className="context--menu__title context--menu__no-hover" disabled>
        <span>{strings.general.additionalActions}</span>
      </Item>
      <Item onClick={createCSV}>
        <img
          className="export-icon"
          src={ExportIcon}
          alt={strings.actionItemsPage.contextMenu.ticketIcon}
        />
        {strings.general.Export}
      </Item>
    </Menu>
  );
};

export default ContextMenu;
