import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useSortBy, useResizeColumns, useFlexLayout, usePagination } from 'react-table';
import { useContextMenu } from 'react-contexify';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import LastSyncedDate from '~reactComponents/LastSyncedDate/LastSyncedDate.react';
import Table from '~reactComponents/Table/Table.react';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import ContextMenu from './ContextMenu/ContextMenu.react';

import { columnFields, TABLE_COLUMNS } from './ReactAddOns.config.react';
import { IRouter, IStore, IRoute } from '~utils/global.types.react';
import { sendRequest } from '~utils/request';
import { handlePageChange } from '~utils/global.helpers.react';

import {
  ORG_DASHBOARD,
  CLUSTER_OVERVIEW,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { ADDONS_PAGE_CONTEXT_MENU_ID } from '~reactHelpers';
import { strings } from '~utils/strings';
import logger from '~utils/logger';
import { createCSVData, convertJsonToCsv, downloadCsv } from '~utils/helpers';

import './ReactAddOns.scss';
interface IRelease {
  Installed: { appVersion: string; version: string; kubeVersion: string };
  Latest: { appVersion: string; version: string; kubeVersion: string };
  chartName: string;
  deprecated: boolean;
  description: string;
  helmVersion: string;
  home: string;
  icon: string;
  namespace: string;
  newest: string;
  outdated: boolean;
  release: string;
  version: string;
}

const ReactAddOns = ({
  route,
  router,
  store,
}: {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
}) => {
  const cluster = route?.params?.cluster || '';
  const org = store().getters.organization.Name;
  const [addons, setAddons] = useState<IRelease[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { show: showContextMenu } = useContextMenu({
    id: ADDONS_PAGE_CONTEXT_MENU_ID,
  });

  useEffect(() => {
    retrieveData();
  }, [cluster]);

  const retrieveData = async () => {
    let data;
    try {
      data = await sendRequest(
        'GET',
        `/v0/organizations/${org}/clusters/${cluster}/add-ons`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_add-ons', e);
    }
    const { releases } = data;

    const sanitizedReleases: IRelease[] = (releases || []).map((release: IRelease) => {
      const releaseVersion =
        release.Installed && release.Installed.version !== ''
          ? release.Installed.version
          : release.version;
      const releaseKubeVersion = release.Installed && release.Installed.kubeVersion;
      const newest =
        release.Latest && release.Latest.version !== '' ? release.Latest.version : release.newest;
      const newestKubeVersion = release.Latest && release.Latest.kubeVersion;
      return { ...release, version: releaseVersion, releaseKubeVersion, newest, newestKubeVersion };
    });

    setAddons(sanitizedReleases.filter((release) => release.Latest.version));
    setIsLoaded(true);
  };

  const allUpToDate = useMemo(
    () => addons.length && addons.filter((release) => release.outdated).length === 0,
    [addons],
  );

  const columns = useMemo(() => TABLE_COLUMNS, [addons]);

  const data = useMemo(() => addons, [addons]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 100,
      },
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
  );

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster,
      href: `/orgs/${org}/clusters/${cluster}`,
    },
    {
      id: 'last',
      label: 'Add-Ons',
      href: ``,
      isActive: true,
    },
  ];

  const createCSV = async () => {
    const formattedAddons = createCSVData(addons, columnFields);
    const csvData = await convertJsonToCsv(formattedAddons, Object.values(columnFields));
    const fileName = `${org}_${cluster}_addons-report`;
    downloadCsv(csvData, fileName);
  };

  return (
    <LayoutReact className="addons">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {isLoaded && !addons.length && (
        <span>
          {strings.addonsSection.noReports}{' '}
          <a href={router().resolve({ name: 'clusterhub-install' }).href}>
            {strings.addonsSection.reinstallAgent}
          </a>{' '}
          {strings.general.with}{' '}
          <span className="addons__code-snippet">--set release-watcher.enabled=true</span>
        </span>
      )}
      {addons.length > 0 && (
        <>
          <div className="addons__page-header">
            <div className="addons__last-synced-div">
              {allUpToDate ? (
                <LastSyncedDate
                  syncedDate={strings.addonsSection.upToDate}
                  syncedDateTitle={strings.addonsSection.addons}
                />
              ) : (
                <LastSyncedDate noneSyncedDateTitle={strings.addonsSection.outOfDate} />
              )}
            </div>
            <button
              className="addons__export-button"
              onClick={(e) => showContextMenu({ event: e })}
              data-cy="additional-actions-button"
            >
              <img src={HorizontalEllipsis} alt="ellipsis icon" />
            </button>
          </div>
          <div className="addons__table-container">
            <Table
              loaded={isLoaded}
              tableInstance={tableInstance}
              caption="table for add ons"
              onItemSelected={() => null}
              className="add-ons-table"
            />
          </div>
        </>
      )}
      <ContextMenu createCSV={createCSV} />
    </LayoutReact>
  );
};

export default ReactAddOns;
