import React from 'react';
import { IRouter } from '~utils/global.types.react';

interface NoDataAvailableTextProps {
  linkPath: string;
  router: () => IRouter;
}

export default function NoDataAvailableText({ linkPath, router }: NoDataAvailableTextProps) {
  return (
    <>
      No data available. Please check your permissions or{' '}
      <a
        href={linkPath}
        onClick={(e) => {
          e.preventDefault();
          router().push({ path: linkPath });
        }}
      >
        reinstall the agent
      </a>
      .
    </>
  );
}
