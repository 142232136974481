import React from 'react';
import { COLORS } from '~utils/styling';

const UserAvatarIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
      <circle cx="15" cy="15" r="15" fill={COLORS.AVATAR_BACKGROUND.AVATAR_PINK} />
      <path
        fill={COLORS.CORE.PRIMARY}
        d="M14.648 14.48a3.49 3.49 0 1 0 0-6.98 3.49 3.49 0 0 0 0 6.98ZM20.296 22.5H9v-3.612a3.6 3.6 0 0 1 3.612-3.613H16.7a3.6 3.6 0 0 1 3.612 3.613l-.015 3.612Z"
      />
    </svg>
  );
};

export default UserAvatarIcon;
