import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useSortBy, useResizeColumns, useFlexLayout, usePagination } from 'react-table';

import DescriptionPanel from './DescriptionPanel/DescriptionPanel.react';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import { severities, TABLE_COLUMNS } from './ReactRBAC.config.react';
import { RBACProps, RBACReport, Rule } from './ReactRBAC.types.react';

import {
  ORG_DASHBOARD,
  CLUSTER_OVERVIEW,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { handlePageChange } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~utils/logger';

import './ReactRBAC.scss';

const ReactRBAC = ({ route, router, store }: RBACProps) => {
  const cluster = route?.params?.cluster || '';
  const org = store().getters.organization.Name;
  const [roles, setRoles] = useState<RBACReport[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<string>('');
  const [selectedRules, setSelectedRules] = useState<Rule[]>([]);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster,
      href: `/orgs/${org}/clusters/${cluster}`,
    },
    {
      id: 'last',
      label: 'RBAC',
      href: ``,
      isActive: true,
    },
  ];

  useEffect(() => {
    getData();
  }, [cluster]);

  const getData = async () => {
    let rbacRoles = [];
    try {
      rbacRoles = await sendRequest(
        'GET',
        `/v0/organizations/${org}/clusters/${cluster}/rbac`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_rbac_data', e);
    }

    setRoles(rbacRoles);
  };

  const columns = useMemo(() => TABLE_COLUMNS, [roles]);
  const data = useMemo(() => roles, [roles]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 200,
      },
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = tableInstance;

  const selectedRow = (item: RBACReport | null) => {
    setSelectedTitle(item ? item?.Details?.name : '');
    setSelectedRules(item ? item?.Rules : []);
  };

  return (
    <LayoutReact>
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="rbac-legend">
        {severities.map((severity) => {
          return (
            <div className="rbac-legend__item">
              <span className="rbac-legend__title">{severity.title}:</span>
              <span>{severity.description}</span>
            </div>
          );
        })}
      </div>
      <div className="rbac-table">
        <div className="rbac-table-container">
          <table {...getTableProps()} className="rbac-table-component__table" data-cy="rbac-table">
            <thead className="rbac-table-header" role={strings.ariaRoles.rowgroup}>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="header-row"
                  {...headerGroup.getHeaderGroupProps()}
                  role={strings.ariaRoles.row}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      role="columnheader"
                    >
                      {column.render('Header')}
                      <span className="rbac-table-header__icon">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-arrow-down" />
                          ) : (
                            <i className="fas fa-arrow-up" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                      <div {...column.getResizerProps()} className="draggable-column" />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rbac-table-body">
              {page &&
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={
                        selectedTitle === row.original.Details.name ? 'selected-policy' : ''
                      }
                      onClick={() => {
                        selectedRow(row.original);
                      }}
                    >
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <DescriptionPanel
          selectedItem={selectedRules}
          setSelectedItem={selectedRow}
          title={selectedTitle}
        />
      </div>
    </LayoutReact>
  );
};

export default ReactRBAC;
