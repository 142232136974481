import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';

import PillBadge from '~reactComponents/PillBadge/PillBadge.react';

import { ConfigReport } from '~globalTypes';

import { strings } from '~utils/strings';
import { getCurrentTimezone } from '~reactHelpers';
import { MediumBasicText } from '~utils/texts.react';

import './Report-IntegrationCard.react.scss';

dayjs.extend(relativeTime);
dayjs.extend(timezone);

type ReportIntegrationCardProps = {
  title: string;
  name: string;
  description: string;
  status: string;
  isOrgOwner: boolean;
  connectIntegration: (integration: string) => void;
  disconnectIntegration: (integration: string) => void;
  image?: string;
  time?: Date | string;
  openReport?: (report: string) => void;
  reportConfig?: ConfigReport;
  isIntegrationPage?: boolean;
  link?: string;
  className: string;
  cardClassName?: string;
};

const ReportIntegrationCard = ({
  title,
  name,
  description,
  status,
  isOrgOwner,
  connectIntegration,
  disconnectIntegration,
  image,
  time,
  openReport,
  reportConfig,
  isIntegrationPage,
  link,
  className = '',
  cardClassName = '',
}: ReportIntegrationCardProps) => {
  const timeZone = getCurrentTimezone();

  // available is passing, pending is , installed is none

  const CheckStatus = (): JSX.Element => {
    if (status === 'installed') {
      return <PillBadge text={strings.installed} color="installed" />;
    } else if (status === 'available') {
      return <PillBadge text={strings.general.available} color="passing" />;
    }
    return <PillBadge text={strings.general.pending} color="none" />;
  };

  const formatTime = (time: Date | string) => {
    return time === ''
      ? ''
      : timeZone
        ? dayjs().tz(timeZone).to(dayjs(time).tz(timeZone))
        : dayjs().to(dayjs(time));
  };

  return (
    <div className={`text-center reportHub-card ${className}`} data-cy="report-card" key={title}>
      <div
        className={clsx('card mini-card mb-3 integration-card', cardClassName, {
          pointer: !link,
          'pending-padding': status === 'pending' || title === strings.workloads,
        })}
      >
        <div
          className="card-body mixin d-flex flex-column"
          onClick={() => (openReport ? openReport(title) : '')}
        >
          <div className="integration-image">
            <img
              className={name === 'AWS Costs' ? 'integration-image--scale-down' : ''}
              src={image || `/static/img/integrations/${title}.svg`}
              role="presentation"
            />
          </div>
          <div className="h5 card-title integrations-card-title mb-0">
            <div className="integration-name">{name}</div>
            {time && (
              <div
                className={MediumBasicText({
                  weight: strings.textStyling.regular,
                  topMargin: strings.textStyling.md,
                  bottomMargin: strings.textStyling.xsBottom,
                })}
              >
                {formatTime(time)}
              </div>
            )}
          </div>
          <div className="dropdown-divider"></div>
          <div className="card-text">{description}</div>
          <p className={`h5 mt-auto ${title === strings.workloads ? 'badge-bottom-margin' : ''}`}>
            <CheckStatus />
          </p>
        </div>
        {!isOrgOwner && title !== strings.workloads && link && (
          <a
            href={link}
            className="install-status-button btn btn-primary"
            target="_blank"
            data-cy="install-status-button"
          >
            {strings.seeDocumentation}
          </a>
        )}
        {isOrgOwner && title !== strings.workloads && (
          <>
            {status === 'available' ||
            (status === 'installed' && !reportConfig && !isIntegrationPage) ? (
              link ? (
                <a
                  href={link}
                  className="install-status-button btn btn-primary"
                  target="_blank"
                  data-cy="install-status-button"
                >
                  {strings.seeDocumentation}
                </a>
              ) : (
                <button
                  className="install-status-button btn btn-primary"
                  type="button"
                  onClick={() => connectIntegration(title)}
                  data-cy="install-status-button"
                >
                  <span>{openReport ? 'Quick Add' : 'Add Integration'}</span>
                </button>
              )
            ) : (
              <button
                className="install-status-button btn btn-outline-primary"
                type="button"
                onClick={() => disconnectIntegration(title)}
                data-cy="install-status-button"
              >
                <span>{strings.Remove}</span>
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReportIntegrationCard;
