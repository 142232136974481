import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import CaretUpIcon from '../Icons/CaretUp.react';

import { strings } from '~utils/strings';
import { ThinTitle } from '~utils/texts.react';

import './CardMetric.react.scss';

type CardMetricProps = {
  isIncrease: boolean;
  color: string;
  metric: number;
  children: React.ReactChildren;
};

const CardMetric = ({ isIncrease, color, metric, children }: CardMetricProps) => {
  const [cardMetric, setCardMetric] = useState<number>(0);
  const metricInterval = useRef<number>();

  // Creates the animation with the increasing number
  useEffect(() => {
    clearInterval(metricInterval.current);
    if (localStorage.getItem('isTesting')) {
      setCardMetric(metric);
      return;
    }
    if (metric === cardMetric) {
      return;
    }

    metricInterval.current = window.setInterval(() => {
      if (metric !== cardMetric) {
        let change = (metric - cardMetric) / 10;
        change = change >= 0 ? Math.ceil(change) : Math.floor(change);
        setCardMetric(cardMetric + change);
      } else {
        window.clearInterval(metricInterval.current);
      }

      return () => window.clearInterval(metricInterval.current);
    }, 45);
  }, [cardMetric, metric]);

  return (
    <div className="card--metric">
      <div className="card--metric--data">
        <span
          className={clsx(
            'card--metric--data__metric',
            ThinTitle({
              weight: strings.textStyling.regular,
              color: strings.textStyling.primary,
              textTransform: 'noCapitalize',
            }),
          )}
        >
          {cardMetric}
        </span>
        <CaretUpIcon fill={color} isFlipped={!isIncrease} />
      </div>
      <span className="card--metric--title">{children}</span>
    </div>
  );
};

export default CardMetric;
