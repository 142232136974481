import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Select from 'react-select';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import CardMetric from '~reactComponents/CardMetric/CardMetric.react';
import LargeBarChart from '~reactComponents/charts/LargeBarChart/LargeBarChart.react';

import {
  dateOptions,
  timelineOptions,
  dataSummaryInit,
  dataSummaryColors,
  dataSummaryOptions,
  dataSummaryGoTo,
} from '~config/clusterOverview.config';
import { colorScheme } from '~config/main.config';

import {
  ActionItem,
  BarData,
  CountSummary,
  DataSummary,
  Criteria,
} from './ActionItemsCard.props.react';
import { OptionType, Organization, Cluster, IRouter } from '~utils/global.types.react';
import { CriticalThreshold, HighThreshold, LowThreshold, MediumThreshold } from '~utils/constants';
import { getCurrentTimezone, hasKey } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import { sortAscending } from '~utils/helpers';

import './ActionItemsCard.react.scss';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);

type ActionItemsCardProps = {
  organization: Organization;
  cluster: Cluster;
  namespaces: string[];
  reportOptions: string[];
  router: () => IRouter;
  className: string;
};

const ActionItemsCard = ({
  organization,
  cluster,
  namespaces,
  reportOptions,
  router,
  className,
}: ActionItemsCardProps) => {
  const timeZone = getCurrentTimezone();
  const [actionItemsToDisplay, setActionItemsToDisplay] = useState<ActionItem[]>([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState<OptionType>({
    value: strings.noTranslate.monthly,
    label: strings.dateOptions.lastMonth,
  });
  const [selectedActionCategory, setSelectedActionCategory] = useState<OptionType>({
    value: strings.noTranslate.fixed,
    label: strings.clusterOverview.fixedTimeline,
  });
  const [selectedNamespaceFilter, setSelectedNamespaceFilter] = useState<OptionType>({
    value: '',
    label: strings.clusterOverview.allNamespaces,
  });
  const [selectedReportFilter, setSelectedReportFilter] = useState<OptionType>({
    value: '',
    label: strings.clusterOverview.allReports,
  });
  const [dataSummary, setDataSummary] = useState<DataSummary>(dataSummaryInit);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterFrom, setFilterFrom] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    init();
  }, [
    selectedActionCategory,
    selectedNamespaceFilter,
    selectedReportFilter,
    selectedDateFilter,
    cluster,
  ]);

  const init = async () => {
    const oneMonthAgo = timeZone
      ? dayjs().subtract(1, 'month').utc().tz(timeZone).toISOString()
      : dayjs().subtract(1, 'month').utc().toISOString();
    const baseUrl = `/v0/organizations/${organization}/clusters/${cluster}`;
    const requests = [
      sendRequest(
        'GET',
        `${baseUrl}/action-items?IncludeDeleted=true&FirstSeen=${oneMonthAgo}&Fixed=false&sortDesc=true&minimalFields=true`,
        { showSuccessAlert: false, cache: true },
        null,
      ),
      sendRequest(
        'GET',
        `${baseUrl}/action-items?IncludeDeleted=true&LastReportedAt=${oneMonthAgo}&Fixed=true&sortDesc=true&minimalFields=true`,
        { showSuccessAlert: false, cache: true },
        null,
      ),
      sendRequest(
        'GET',
        `${baseUrl}/action-items?FirstSeen=${oneMonthAgo}&Fixed=false&Resolution=None`,
        { showSuccessAlert: false, cache: true },
        null,
      ),
    ];

    try {
      const [firstSeen, fixedActionItems, retrievedNewActionItems] = await Promise.all(requests);
      filterActionItems([...firstSeen, ...fixedActionItems], retrievedNewActionItems);
    } catch (e) {
      logger.logError('error_fetching_action_items_card', e);
    }
  };

  const filterActionItems = (actionItems: ActionItem[], newActionItems: ActionItem[]) => {
    // Holds the action items used to check for increase/decrease in count
    const fixedActionItemsDelta = [];
    const unresolvedActionItemsDelta = [];
    const actionItemsWithinTimePeriod: ActionItem[] = []; // Hold both fixed and unresolved AI
    const dataSummary = JSON.parse(JSON.stringify(dataSummaryInit));
    const today = timeZone ? dayjs().utc().tz(timeZone).toISOString() : dayjs().utc().toISOString();
    let dateToFilterFrom: string;
    let deltaFilterDate: string;

    switch (selectedDateFilter.value) {
      case strings.noTranslate.weekly:
        dateToFilterFrom = timeZone
          ? dayjs().subtract(1, 'week').utc().tz(timeZone).toISOString()
          : dayjs().subtract(1, 'week').utc().toISOString();
        deltaFilterDate = timeZone
          ? dayjs().subtract(2, 'week').utc().tz(timeZone).toISOString()
          : dayjs().subtract(2, 'week').utc().toISOString();
        break;
      case strings.noTranslate.monthly:
        dateToFilterFrom = timeZone
          ? dayjs().subtract(1, 'month').utc().tz(timeZone).toISOString()
          : dayjs().subtract(1, 'month').utc().toISOString();
        deltaFilterDate = timeZone
          ? dayjs().subtract(1, 'month').utc().tz(timeZone).toISOString()
          : dayjs().subtract(1, 'month').utc().toISOString();
        break;
      default: // daily
        dateToFilterFrom = timeZone
          ? dayjs().subtract(1, 'day').utc().tz(timeZone).toISOString()
          : dayjs().subtract(1, 'day').utc().toISOString();
        deltaFilterDate = timeZone
          ? dayjs().subtract(2, 'day').utc().tz(timeZone).toISOString()
          : dayjs().subtract(2, 'day').utc().toISOString();
        break;
    }

    const finalTime = timeToDisplay(dateToFilterFrom);
    setFilterFrom(finalTime);

    const newFixedActionItems: ActionItem[] = [];
    const newUnresolvedActionItems: ActionItem[] = [];
    // Filters all actions items based on severity, timeframe, namespace, reports, and more
    actionItems.forEach((actionItem) => {
      const { FirstSeen, Fixed, Severity, Resolution } = actionItem;
      if (FirstSeen >= dateToFilterFrom && FirstSeen <= today) {
        if (Fixed) {
          newFixedActionItems.push(actionItem);
        } else {
          newUnresolvedActionItems.push(actionItem);
          if (!actionItem.DeletedAt) {
            if (Severity >= CriticalThreshold) {
              dataSummary.criticalActionItems.count++;
            } else if (Severity >= HighThreshold) {
              dataSummary.highActionItems.count++;
            }
            if (Resolution) {
              dataSummary.resolvedActionItems.count++;
            }
          }
        }
        actionItemsWithinTimePeriod.push(actionItem);
        // Section below is to save the delta of the summary data
      } else if (FirstSeen >= deltaFilterDate && FirstSeen <= dateToFilterFrom) {
        if (Fixed) {
          fixedActionItemsDelta.push(actionItem);
        } else {
          unresolvedActionItemsDelta.push(actionItem);
          if (Severity >= CriticalThreshold) {
            dataSummary.criticalActionItems.delta++;
          } else if (Severity >= HighThreshold) {
            dataSummary.highActionItems.delta++;
          }
          if (Resolution) {
            dataSummary.resolvedActionItems.delta++;
          }
        }
      }
    });

    const newFilteredActionItems: ActionItem[] = [];

    if (newActionItems.length) {
      newActionItems.forEach((actionItem) => {
        const { FirstSeen } = actionItem;
        if (FirstSeen >= dateToFilterFrom && FirstSeen <= today) {
          newFilteredActionItems.push(actionItem);
        }
      });
    }

    dataSummary.newActionItems.count = newFilteredActionItems.filter(
      (actionItem) => !actionItem.DeletedAt,
    ).length;
    dataSummary.fixedActionItems.count = newFixedActionItems.filter(
      (actionItem) => !actionItem.DeletedAt,
    ).length;

    setDataSummary({ ...dataSummaryInit, ...dataSummary });
    setActionItemsToDisplay(actionItemsWithinTimePeriod);
  };

  const filterActionItemsByCategory = (actionItems: ActionItem[]) => {
    if (!actionItems?.length) {
      return actionItems;
    }
    switch (selectedActionCategory.value) {
      case strings.noTranslate.fixed:
        return actionItems.filter((item) => item.Fixed);
      case strings.noTranslate.new:
        return actionItems.filter((item) => !item.Fixed && !item.Resolution);
      case strings.noTranslate.resolved:
        return actionItems.filter((item) => item.Resolution);
      case 'high':
        return actionItems.filter((item) => item.Severity >= HighThreshold);
      case 'critical':
        return actionItems.filter((item) => item.Severity >= CriticalThreshold);
      default:
        return actionItems;
    }
  };

  const timeToDisplay = (date) => {
    const hour = dayjs(date).format('YYYY-MM-DDTHH:00:00Z');
    const month = dayjs(date).format('YYYY-MM-DDT00:00:00Z');
    return selectedDateFilter.value === strings.noTranslate.hourly ? hour : month;
  };

  const buildDataObj = (dataObj: BarData, actionItem: ActionItem) => {
    switch (selectedActionCategory.value) {
      case strings.noTranslate.fixed:
      case strings.noTranslate.resolved:
        dataObj.fixed++;
        return dataObj;
      case 'critical':
        dataObj.fixed -= actionItem.Fixed ? 1 : 0;
        dataObj.critical++;
        return dataObj;
      case 'high':
        dataObj.fixed -= actionItem.Fixed ? 1 : 0;
        dataObj.high++;
        return dataObj;
      case strings.noTranslate.new:
      default:
        if (actionItem.Severity >= CriticalThreshold) {
          dataObj.critical++;
        } else if (actionItem.Severity >= HighThreshold) {
          dataObj.high++;
        } else if (actionItem.Severity >= MediumThreshold) {
          dataObj.medium++;
        } else if (actionItem.Severity >= LowThreshold) {
          dataObj.low++;
        } else {
          dataObj.none++;
        }
        return dataObj;
    }
  };

  const formattedData = useMemo(() => {
    const toDisplay: BarData[] = [];
    const filteredActionItems = filterActionItemsByCategory(actionItemsToDisplay);
    let finalFilteredActionItems = filteredActionItems;

    if (selectedNamespaceFilter.value) {
      finalFilteredActionItems = finalFilteredActionItems.filter(
        (actionItem) => actionItem.ResourceNamespace === selectedNamespaceFilter.value,
      );
    }
    if (selectedReportFilter.value) {
      finalFilteredActionItems = finalFilteredActionItems.filter(
        (actionItem) => actionItem.ReportType === selectedReportFilter.value,
      );
    }

    let current = timeZone ? dayjs(filterFrom).utc().tz(timeZone) : dayjs(filterFrom).utc();
    const today = timeZone ? dayjs().utc().tz(timeZone) : dayjs().utc();

    while (current < today) {
      const dataObj = {
        date: '',
        fixed: 0,
        fixedColor: COLORS.CHARTS.TIMELINE.FIXED,
        none: 0,
        noneColor: COLORS.CHARTS.TIMELINE.NONE,
        low: 0,
        lowColor: COLORS.CHARTS.TIMELINE.LOW,
        medium: 0,
        mediumColor: COLORS.CHARTS.TIMELINE.MEDIUM,
        high: 0,
        highColor: COLORS.CHARTS.TIMELINE.HIGH,
        critical: 0,
        criticalColor: COLORS.CHARTS.TIMELINE.CRITICAL,
      };
      current = dayjs(current).add(1, 'day');
      const updatedCurrent = timeZone
        ? dayjs(current).utc().tz(timeZone).toISOString()
        : dayjs(current).utc().toISOString();
      const finalTime = timeToDisplay(updatedCurrent);
      dataObj.date = finalTime;
      toDisplay.push(dataObj);
    }

    (finalFilteredActionItems || []).forEach((datum) => {
      const dataObj = {
        date: '',
        fixed: 0,
        fixedColor: COLORS.CHARTS.TIMELINE.FIXED,
        none: 0,
        noneColor: COLORS.CHARTS.TIMELINE.NONE,
        low: 0,
        lowColor: COLORS.CHARTS.TIMELINE.LOW,
        medium: 0,
        mediumColor: COLORS.CHARTS.TIMELINE.MEDIUM,
        high: 0,
        highColor: COLORS.CHARTS.TIMELINE.HIGH,
        critical: 0,
        criticalColor: COLORS.CHARTS.TIMELINE.CRITICAL,
      };

      const finalTime = timeToDisplay(datum.FirstSeen);

      const matchedData = toDisplay.length
        ? toDisplay.find((item) => item.date === finalTime)
        : false;
      if (matchedData) {
        buildDataObj(matchedData, datum);
      } else {
        dataObj.date = finalTime;
        const newData = buildDataObj(dataObj, datum);
        toDisplay.push(newData);
      }
    });

    setIsLoading(false);
    return toDisplay.sort((a, b) => sortAscending(a.date, b.date));
  }, [actionItemsToDisplay]);

  const keysForChart = useMemo(() => {
    switch (selectedActionCategory.value) {
      case strings.noTranslate.fixed:
        return [strings.noTranslate.fixed];
      case 'critical':
        return ['critical', strings.noTranslate.fixed];
      case 'high':
        return ['high', strings.noTranslate.fixed];
      case strings.noTranslate.resolved:
        return [strings.noTranslate.resolved];
      case strings.noTranslate.new:
      default:
        return ['critical', 'high', 'medium', 'low', 'none'];
    }
  }, [actionItemsToDisplay]);

  const tooltip = (data: any) => {
    const formatter = timeZone ? dayjs(data.date).tz(timeZone) : dayjs(data.date);
    return (
      <div className="action-items-card__tooltip">
        <div>
          <span>
            {selectedDateFilter.value === strings.noTranslate.hourly
              ? formatter.format('M/D hA')
              : formatter.format('M/D')}
          </span>
        </div>
        {keysForChart.map((key, index) => {
          return (
            <div className="action-items-card__div" key={`action-items-chart-${index}`}>
              <div
                className="action-items-card__square"
                style={{
                  backgroundColor: data[`${key}Color`],
                }}
              ></div>
              <strong>{key}:</strong>
              <span>{hasKey(data, key) && data[key] ? data[key] : 0}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const barChartData = {
    data: formattedData,
    keys: keysForChart,
    indexBy: 'date',
    margin: { top: 10, right: 0, bottom: 50, left: 35 },
    colors: (datum: any) => datum.data[`${datum.id}Color`],
    axisBottom: {
      format: (value: Date) => {
        const formatter = timeZone ? dayjs(value).tz(timeZone) : dayjs(value);
        return selectedDateFilter.value === strings.noTranslate.hourly
          ? formatter.format('M/D hA')
          : formatter.format('M/D');
      },
      tickValues:
        selectedDateFilter.value === strings.noTranslate.hourly
          ? strings.noTranslate.every4Hours
          : 'every 1 days',
      tickRotation: -45,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    },
    tooltip: tooltip,
  };

  const namespaceOptions = useMemo(() => {
    const allOptions = namespaces.map((namespace: string) => ({
      value: namespace,
      label: namespace,
    }));
    allOptions.unshift({ value: '', label: strings.clusterOverview.allNamespaces });
    return allOptions;
  }, [namespaces]);

  const formattedReportOptions = useMemo(() => {
    const allOptions = reportOptions.map((report: string) => ({ value: report, label: report }));
    allOptions.unshift({ value: '', label: strings.clusterOverview.allReports });
    return allOptions;
  }, [reportOptions]);

  const isIncrease = (summaryObj: CountSummary) => summaryObj.delta < summaryObj.count;

  const metricColor = (summaryObj: CountSummary, key: string) => {
    const didIncrease = isIncrease(summaryObj);
    if (hasKey(dataSummaryColors, key) && dataSummaryColors[key]) {
      return dataSummaryColors[key];
    }
    if (key === 'fixedActionItems' && didIncrease) {
      return colorScheme.passing;
    }
    return didIncrease ? colorScheme.critical : colorScheme.passing;
  };

  const formatQuery = (criteria: Criteria) => {
    if (selectedNamespaceFilter.value) {
      criteria.ResourceNamespace = selectedNamespaceFilter.value;
    }
    if (selectedReportFilter.value) {
      criteria.ReportType = selectedReportFilter.value;
    }
    return { path: `/orgs/${organization}/clusters/${cluster}/action-items/`, query: criteria };
  };

  return (
    <Card className={className}>
      <Card.Header className="action-items-card__header">
        <div className="action-items-card__dropdowns-container">
          <div className="action-items-card__date-select-container">
            <Select
              aria-label="timeline options select"
              className="action-items-card__date-select timeline"
              classNamePrefix="action-items-card__date-select"
              isSearchable={false}
              options={timelineOptions}
              value={selectedActionCategory}
              onChange={setSelectedActionCategory}
            />
          </div>
        </div>
        <div className="action-items-card__dropdowns-container">
          <div
            className="action-items-card__date-select-container"
            data-cy="overview-actionItems-card__date-time"
          >
            <Select
              aria-label="date options select"
              className="action-items-card__date-select"
              classNamePrefix="action-items-card__date-select"
              isSearchable={false}
              options={dateOptions}
              value={selectedDateFilter}
              onChange={setSelectedDateFilter}
            />
          </div>
          <div
            className="action-items-card__date-select-container"
            data-cy="overview-actionItems-card__namespaces"
          >
            <Select
              aria-label="namespace options select"
              className="action-items-card__date-select"
              classNamePrefix="action-items-card__date-select"
              isSearchable={false}
              options={namespaceOptions}
              value={selectedNamespaceFilter}
              onChange={setSelectedNamespaceFilter}
            />
          </div>
          <div
            className="action-items-card__date-select-container"
            data-cy="overview-actionItems-card__reports"
          >
            <Select
              aria-label="report options select"
              className="action-items-card__date-select"
              classNamePrefix="action-items-card__date-select"
              isSearchable={false}
              options={formattedReportOptions}
              value={selectedReportFilter}
              onChange={setSelectedReportFilter}
            />
          </div>
        </div>
      </Card.Header>
      <Card.Body padded>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div className="action-items-card__table-container">
            <LargeBarChart {...barChartData} />
          </div>
        )}
      </Card.Body>
      <div className="action-items-card__metric-container">
        {Object.keys(dataSummary).map((key, index) => (
          <div
            key={`action-items-onclick-${index}`}
            onClick={() =>
              router().push(
                formatQuery(
                  dataSummaryGoTo(
                    cluster,
                    selectedDateFilter,
                    selectedNamespaceFilter,
                    selectedReportFilter,
                  )[key],
                ),
              )
            }
          >
            <CardMetric
              isIncrease={isIncrease(dataSummary[key])}
              color={metricColor(dataSummary, key)}
              metric={hasKey(dataSummary, key) ? dataSummary[key].count : 0}
              children={hasKey(dataSummaryOptions, key) ? dataSummaryOptions[key] : ''}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default ActionItemsCard;
