import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import clsx from 'clsx';

import { PieData } from '~utils/global.types.react';

import { strings } from '~utils/strings';
import { ThinTitle } from '~utils/texts.react';

import './DonutChart.react.scss';

// data structure needed for graph: [{ id, label, value, color } - for each section on chart]

type DonutChartProps = {
  data: PieData[];
  centerInfo?: string;
  label: string;
  margin?: Record<string, number>;
  tooltip?: (data: any) => JSX.Element;
  enableArcLinkLabels?: boolean;
  enableArcLabels?: boolean;
  centerInfoClassName?: string;
  centerInfoNode?: React.ReactNode;
};

const DonutChart = ({
  data,
  centerInfo,
  label,
  margin = { top: 7, right: 8, bottom: 10, left: 8 },
  tooltip,
  enableArcLinkLabels = false,
  enableArcLabels = false,
  centerInfoClassName,
  centerInfoNode,
}: DonutChartProps) => {
  return (
    <div aria-label={`${label} ${centerInfo}`} className="base-pie-chart">
      <ResponsivePie
        data={data || []}
        margin={margin}
        innerRadius={0.85}
        padAngle={2}
        cornerRadius={5}
        enableArcLinkLabels={enableArcLinkLabels}
        enableArcLabels={enableArcLabels}
        colors={{ datum: 'data.color' }}
        activeOuterRadiusOffset={2}
        activeInnerRadiusOffset={2}
        borderWidth={2.5}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        motionConfig={{
          mass: 1,
          tension: 429,
          friction: 1,
          clamp: true,
          precision: 0.01,
          velocity: 0,
        }}
        tooltip={tooltip ? ({ datum }) => tooltip(datum) : undefined}
      />
      {centerInfo && (
        <span
          className={
            centerInfoClassName
              ? centerInfoClassName
              : clsx(
                  'base-pie-chart__score',
                  ThinTitle({
                    weight: strings.textStyling.bold,
                    color: strings.textStyling.primary,
                  }),
                )
          }
        >
          {centerInfo}
        </span>
      )}
      {centerInfoNode && centerInfoNode}
    </div>
  );
};

export default DonutChart;
