<template>
  <div>
    <component :is="component" v-bind:org="org" v-bind:cluster="cluster"></component>
  </div>
</template>

<script>
import Cluster from '@/views/cluster/index.vue';
import ErrorPage from '@/views/error/error.vue';
import Loading from '@/components/loading/index.vue';
import { UPDATE_CLUSTER, SET_IS_ERROR_PAGE_SHOWN } from '@/store/action.types';

export default {
  props: {
    org: {
      type: String,
      required: true,
    },
    cluster: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch(UPDATE_CLUSTER, this.$route.params.cluster);
    } catch (e) {
      this.$store.commit(SET_IS_ERROR_PAGE_SHOWN, true);
    }
  },
  computed: {
    component() {
      if (this.$store.getters.isErrorPageShown) {
        return 'ErrorPage';
      }
      if (this.$store.getters.cluster === null) {
        return 'Loading';
      }
      return 'Cluster';
    },
  },
  components: {
    Cluster,
    ErrorPage,
    Loading,
  },
};
</script>
