import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';

import { IStore, ReportsStatus } from '~utils/global.types.react';

import { getCurrentTimezone } from '~reactHelpers';
import { sortDescending } from '~utils/helpers';
import { strings } from '~utils/strings';
import { CardTitleDefault, XSCardTitle } from '~utils/texts.react';

import './LatestActivity.react.scss';

dayjs.extend(relativeTime);
dayjs.extend(timezone);

type LatestActivityProps = { store: () => IStore };

const LatestActivity = ({ store }: LatestActivityProps) => {
  const [reports, setReports] = useState<ReportsStatus[]>([]);
  const reportsStatus = store().getters.reportsStatus;
  const timeZone = getCurrentTimezone();

  useEffect(() => {
    if (reportsStatus && Object.keys(reportsStatus).length) {
      const allReports = Object.keys(reportsStatus).map((report) => reportsStatus[report]);
      setReports(
        allReports
          .filter((report) => report.LastSeen)
          .sort((a, b) => sortDescending(a.LastSeen, b.LastSeen))
          .slice(0, 6),
      );
    } else {
      setReports([]);
    }
  }, [reportsStatus]);

  const lastSeenReport = (date: string) => {
    return timeZone ? dayjs().tz(timeZone).to(dayjs(date).tz(timeZone)) : dayjs().to(dayjs(date));
  };

  return (
    <Card className="cluster-latest-activity">
      <Card.Header
        className={clsx(
          'cluster-latest-activity__title',
          CardTitleDefault({ weight: strings.textStyling.regular }),
        )}
      >
        Latest Activity
      </Card.Header>
      <Card.Body padded>
        <div className="cluster-latest-activity__timeline">
          <ul>
            {reports.map((report, index) => (
              <li key={`latest-activity-${index}`}>
                <div className="report">
                  <span className={XSCardTitle({ weight: strings.textStyling.medium })}>
                    {report.DisplayName}
                  </span>
                  <span className={XSCardTitle()}>{lastSeenReport(report.LastSeen || '')}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LatestActivity;
