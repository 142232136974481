import React from 'react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import { severities } from '../ReactRBAC.config.react';
import { strings } from '~utils/strings';

export const TABLE_COLUMNS = [
  {
    Header: strings.columnTitles.Severity,
    accessor: 'Severity',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const matchingSeverity = severities.find(
        (severity) => severity.score === value || (value <= 1 && severity.score === 1),
      );
      return (
        <PillBadge
          color={matchingSeverity?.variant || 'primary'}
          text={matchingSeverity?.title || ''}
        />
      );
    },
    minWidth: 25,
  },
  {
    Header: strings.rbac.Verbs,
    accessor: 'Rule.verbs',
    className: 'no-overflow',
    Cell: ({ value }: { value: string[] }) => {
      return <span>{value.join(', ')}</span>;
    },
    minWidth: 150,
  },
  {
    Header: strings.rbac.apiGroups,
    accessor: 'Rule.apiGroups',
    className: 'no-overflow',
    minWidth: 200,
  },
  {
    Header: strings.rbac.Resources,
    accessor: 'Rule.resources',
    className: 'no-overflow',
    Cell: ({ value }: { value: string[] }) => {
      return <span>{value?.join(', ') || 'None'}</span>;
    },
    minWidth: 350,
  },
];
