import React from 'react';
import Tag from '~reactComponents/Tags/Tag.react';
import { Row } from 'react-table';
import { strings } from '~utils/strings';

export const columnFields = {
  release: strings.general.Release,
  description: strings.general.Description,
  namespace: strings.efficiency.Namespace,
  helmVersion: strings.helmVersion,
  deprecated: strings.Deprecated,
  outdated: strings.Outdated,
  version: strings.general.Version,
  newest: strings.Newest,
};

export const TABLE_COLUMNS = [
  {
    Header: strings.general.Release,
    accessor: 'release',
    className: 'no-overflow',
    Cell: ({ value, row }: { value: string; row: Row }) => {
      return (
        <span title={value}>
          {row.original.icon && (
            <img src={row.original.icon} className="chart-logo" alt={`Logo for ${value} add on`} />
          )}
          {value}
        </span>
      );
    },
    minWidth: 200,
  },
  {
    Header: strings.general.Description,
    accessor: 'description',
    className: 'no-overflow',
    Cell: ({ value, row }: { value: string; row: Row }) => {
      return (
        <div>
          <span title={value}>{value}</span>
          <br />
          {row.original.home && (
            <a href={row.original.home} target="_blank">
              {row.original.home}
            </a>
          )}
        </div>
      );
    },
    minWidth: 250,
  },
  {
    Header: strings.efficiency.Namespace,
    accessor: 'namespace',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 150,
  },
  {
    Header: strings.helmVersion,
    accessor: 'helmVersion',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 50,
  },
  {
    Header: strings.Deprecated,
    accessor: 'deprecated',
    className: 'no-overflow',
    Cell: ({ value }: { value: boolean }) => {
      const formattedValue = value === true ? strings.general.Yes : strings.general.No;
      return <span title={formattedValue}>{formattedValue}</span>;
    },
    minWidth: 100,
  },
  {
    Header: strings.Outdated,
    accessor: 'outdated',
    className: 'no-overflow',
    Cell: ({ value }: { value: boolean }) => {
      const formattedValue = value === true ? strings.addOns.outOfDate : strings.addOns.upToDate;
      return (
        <Tag
          variant={value ? 'outdated' : 'updated'}
          tagClassNames={`addons__badge ${value ? 'outdated-badge' : 'updated-badge'}`}
        >
          {formattedValue}
        </Tag>
      );
    },
  },
  {
    Header: strings.general.Version,
    accessor: 'version',
    className: 'no-overflow',
    Cell: ({ value, row }: { value: string; row: Row }) => {
      return (
        <div>
          <span title={value}>{value}</span>
          <br />
          {row.original.releaseKubeVersion && (
            <span>(kubeVersion: {row.original.releaseKubeVersion})</span>
          )}
        </div>
      );
    },
    minWidth: 225,
  },
  {
    Header: strings.Newest,
    accessor: 'newest',
    className: 'no-overflow',
    Cell: ({ value, row }: { value: string; row: Row }) => {
      return (
        <div>
          <span title={value}>{value}</span>
          <br />
          {row.original.newestKubeVersion && (
            <span>(kubeVersion: {row.original.newestKubeVersion})</span>
          )}
        </div>
      );
    },
    minWidth: 225,
  },
];
