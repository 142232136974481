import React, { useState, useEffect, useMemo } from 'react';
import { Button, FormCheck } from 'react-bootstrap';
// import { toast } from 'react-hot-toast';

import { TOGGLE_TOAST, UPDATE_REPORTS_CONFIGS } from '~store/action.types';

import { ConfigReportOptions, IRoute, IRouter, IStore } from '~utils/global.types.react';

import { getAllImages } from '~utils/constants';
import logger from '~utils/logger';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import { CardTitleMargins, BasicText } from '~utils/texts.react';

import './style.scss';

interface ReportHubConfigureProps {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
}

const DEFAULT_REPORTS = {
  polaris: false,
  opa: false,
  pluto: false,
};

const admissionReports = ['polaris', 'opa', 'pluto'];

export default function ReportHubConfigure({ route, router, store }: ReportHubConfigureProps) {
  const { isOrgOwner, cluster, reportsConfigs } = store().getters;
  const [passiveMode, setPassiveMode] = useState(false);
  const [orgSettingsDefault, setOrgSettingsDefault] = useState(false);
  const [reports, setReports] = useState<Record<string, boolean>>(DEFAULT_REPORTS);
  const [options, setOptions] = useState<ConfigReportOptions>({ schedule: '', timeout: 0 });
  const [images] = useState(getAllImages());
  const report = route?.params?.report;

  const { installPrometheus, address, installVPA, defaultToOrg, privateImages } =
    (reportsConfigs[report] && (reportsConfigs[report].Options as any)) || {};

  const valid = useMemo(() => {
    return (
      options?.schedule ||
      options?.timeout ||
      installPrometheus ||
      address ||
      installVPA ||
      defaultToOrg ||
      privateImages
    );
  }, [
    options?.schedule,
    options?.timeout,
    installPrometheus,
    address,
    installVPA,
    defaultToOrg,
    privateImages,
  ]);

  const baseURL = `/v0/organizations/${store().getters.organization.Name}`;

  useEffect(() => {
    const reportOptions = reportsConfigs[report] && reportsConfigs[report].Options;
    setOptions(reportOptions);
  }, [reportsConfigs, report, cluster]);

  useEffect(() => {
    async function getAdmissionData() {
      const clusterResponse = await getClusterAdmissionData();
      if (!clusterResponse) {
        const settings = await getOrgAdmissionData();
        if (!settings) {
          setReports(DEFAULT_REPORTS);
          setOrgSettingsDefault(true);
          setPassiveMode(true);
        } else {
          setReports({
            polaris: settings.polarisEnabled ?? true,
            opa: settings.opaEnabled ?? true,
            pluto: settings.plutoEnabled ?? true,
          });
          setPassiveMode(settings.passiveMode ?? true);
          setOrgSettingsDefault(true);
        }
      } else {
        setOrgSettingsDefault(false);
        setPassiveMode(clusterResponse.passiveMode ?? true);
        setReports({
          polaris: clusterResponse.polarisEnabled ?? true,
          opa: clusterResponse.opaEnabled ?? true,
          pluto: clusterResponse.plutoEnabled ?? true,
        });
      }
    }

    getAdmissionData();
  }, [cluster]);

  async function saveOrUpdate() {
    const method = reportsConfigs[report] ? 'PATCH' : 'POST';
    const payload = {
      report,
      method,
      options,
    };
    await store().dispatch(UPDATE_REPORTS_CONFIGS, payload);
    const message = reportsConfigs[report] ? 'Configuration Updated' : 'Configuration Added';
    store().dispatch(TOGGLE_TOAST, {
      message,
      position: 'b-toaster-top-center',
      type: 'success',
    });
  }

  async function updateSettings() {
    const data = {
      polarisEnabled: reports.polaris,
      opaEnabled: reports.opa,
      plutoEnabled: reports.pluto,
      passiveMode,
    };
    try {
      if (orgSettingsDefault) {
        await sendRequest('POST', `${baseURL}/admission/settings`, { data }, null);
      } else {
        await sendRequest(
          'POST',
          `${baseURL}/clusters/${cluster.Name}/admission/settings`,
          { data },
          null,
        );
      }
      store().dispatch(TOGGLE_TOAST, {
        message: 'Changed admission controller settings!',
        position: 'b-toaster-top-center',
        type: 'success',
      });
    } catch (e: any) {
      logger.logError('error_updating_admission_settings', e);
      store().dispatch(TOGGLE_TOAST, {
        message: 'Unable to change admission controller settings. Please try again',
        position: 'b-toaster-top-center',
        type: 'error',
      });
    }
  }

  function getImage(name: string) {
    return images[name.toLowerCase()] || images.default;
  }

  async function getClusterAdmissionData() {
    try {
      return await sendRequest(
        'GET',
        `${baseURL}/clusters/${cluster.Name}/admission/settings`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_cluster_admission_settings', e);
      return false;
    }
  }
  async function getOrgAdmissionData() {
    try {
      return await sendRequest('GET', `${baseURL}/admission/settings`, {}, null);
    } catch (e) {
      logger.logError('error_retrieving_org_admission_settings', e);
      return false;
    }
  }

  return (
    <div className="report-hub-configure">
      {report !== strings.reportHub.admission && (
        <>
          <h2 className={CardTitleMargins({ size: strings.textStyling.xl })}>Report Settings</h2>
          <p>You can customize your reports cadence and schedule</p>
          <div className="row">
            <div className="col-9 col-xl-8">
              {report !== 'prometheus-metrics' && (
                <>
                  <div className="input-group mb-3 row">
                    <div className="col-3 d-flex align-items-center">
                      <div className="input-group-prepend mr-3">
                        <span className="font-weight-bold">Schedule</span>
                      </div>
                    </div>
                    <div className="col-9 d-flex">
                      <select
                        className="custom-select rounded"
                        value={options?.schedule || ''}
                        aria-label="schedule select"
                        disabled={!isOrgOwner}
                        onChange={(event) => {
                          setOptions({
                            ...options,
                            schedule: event.currentTarget.value,
                          });
                        }}
                      >
                        <option value="">Picker</option>
                        <option value="rand * * * *">Every hour</option>
                        <option value="rand */3 * * *">Every 3 hours</option>
                        <option value="rand 0 * * *">Every Day</option>
                      </select>
                      <input
                        className="form-control ml-2"
                        type="text"
                        value={options?.schedule || ''}
                        placeholder="Custom schedule"
                        aria-label="custom schedule"
                        disabled={!isOrgOwner}
                        onChange={(event) => {
                          setOptions({
                            ...options,
                            schedule: event.currentTarget.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="input-group mb-3 row">
                <div className="col-3 d-flex align-items-center">
                  <div className="input-group-prepend mr-3">
                    <span className="font-weight-bold" aria-label="timeout label">
                      Timeout
                    </span>
                  </div>
                </div>
                <div className="col-9 d-flex">
                  <select
                    className="custom-select rounded"
                    value={options?.timeout || 0}
                    aria-label="timeout Select"
                    disabled={!isOrgOwner}
                    onChange={(event) => {
                      setOptions({
                        ...options,
                        timeout: parseInt(event.currentTarget.value),
                      });
                    }}
                  >
                    <option value={0}>Picker</option>
                    <option value={60}>60s</option>
                    <option value={300}>300s</option>
                    <option value={1200}>1200s</option>
                    <option value={2400}>2400s</option>
                  </select>
                  <input
                    className="form-control ml-2"
                    type="text"
                    value={options?.timeout || ''}
                    placeholder="Custom timeout"
                    aria-label="timeout input"
                    disabled={!isOrgOwner}
                    onInput={(event) => {
                      setOptions({
                        ...options,
                        timeout: parseInt(event.currentTarget.value),
                      });
                    }}
                  />
                </div>
              </div>
              <button
                className="btn btn-primary mt-3"
                onClick={saveOrUpdate}
                disabled={!valid && !isOrgOwner}
              >
                {!reportsConfigs[report] ? (
                  <span>Add Config Options</span>
                ) : (
                  <span>Update Config Options</span>
                )}
              </button>
            </div>
          </div>
        </>
      )}

      {report === strings.reportHub.admission && (
        <>
          <div className="configure-admission">
            <h2 className={CardTitleMargins({ size: strings.textStyling.xl })}>
              Admission Controller
            </h2>
            <p
              className={BasicText({
                size: strings.textStyling.md,
                bottomMargin: strings.textStyling.xsBottom,
              })}
            >
              Set your Admission Controller to Passive Mode and turn reports on and off.
            </p>
          </div>
          <div className="configure-admission">
            <h2 className={CardTitleMargins({ size: strings.textStyling.xl })}>Blocking Reports</h2>
            <p
              className={BasicText({
                size: strings.textStyling.md,
                bottomMargin: strings.textStyling.xsBottom,
              })}
            >
              Choose which reports should block admission requests.
              <div className="configure-reports">
                {admissionReports.map((_report) => (
                  <div className="configure-report-div">
                    <h3
                      className={BasicText({
                        size: strings.textStyling.xl,
                        weight: strings.textStyling.thin,
                        textTransform: strings.textStyling.capitalize,
                        topMargin: strings.textStyling.lg,
                      })}
                    >
                      {_report === 'opa' ? 'OPA' : _report}
                    </h3>
                    <div className="configure-report-image">
                      <img className="mr-2" src={getImage(_report)} alt="report icon" />
                      <FormCheck
                        className="configure-switch"
                        checked={reports[_report]}
                        name="report-toggle-button"
                        id={`report-toggle-button-${_report}`}
                        type="switch"
                        size="lg"
                        onChange={(event) => {
                          setReports({
                            ...reports,
                            [_report]: event.currentTarget.checked,
                          });
                        }}
                        disabled={!isOrgOwner}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </p>
          </div>
          <div className="configure-admission">
            <h2 className={CardTitleMargins({ size: strings.textStyling.xl })}>Passive Mode</h2>
            <p
              className={BasicText({
                size: strings.textStyling.md,
                bottomMargin: strings.textStyling.xsBottom,
              })}
            >
              In Passive Mode the Admission Controller will not block any admission requests.
            </p>
            <FormCheck
              className="configure-toggle-passive"
              checked={passiveMode}
              name="report-toggle-button"
              id="report-toggle-button-passive"
              type="switch"
              size={32}
              disabled={!isOrgOwner}
              onChange={(event) => {
                setPassiveMode(!!event.currentTarget.checked);
              }}
            />
          </div>
          <div className="configure-org-default">
            <FormCheck
              checked={orgSettingsDefault}
              disabled={!isOrgOwner}
              onChange={(event) => {
                setOrgSettingsDefault(event.currentTarget.checked);
              }}
            />
            Save these as organization's default settings
          </div>
          <Button
            className="mt-4"
            variant="primary"
            onClick={() => updateSettings()}
            disabled={!isOrgOwner}
            data-cy="update-settings-button"
          >
            Update
          </Button>
        </>
      )}
    </div>
  );
}
