import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import toast from 'react-hot-toast';
import { useContextMenu } from 'react-contexify';

import ContextMenu from '../../../components/ContextMenu/ContextMenu.react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import Tag from '~reactComponents/Tags/Tag.react';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import CloseIcon from '~reactComponents/Icons/Close.icon.react';

import { ActionItem, Member, TicketModal } from '../../../ActionItems.types.react';
import { IRouter, IRoute } from '~globalTypes';

import { CONTEXT_MENU_ID, CONTENT, TOP_ROW } from '../../../ActionItems.config.react';
import { sendRequest } from '~utils/request';
import { dateFromToday } from '~utils/helpers';
import logger from '~logger';

import './ActionItemModal.react.scss';
import 'react-contexify/dist/ReactContexify.css';

type ModalProps = {
  route: IRoute;
  router: () => IRouter;
  show: boolean;
  setTicketModal: React.Dispatch<React.SetStateAction<TicketModal>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  actionItem: ActionItem;
  baseURL: string;
  resolveItem: React.Dispatch<React.SetStateAction<number>>;
  isEditable: boolean;
  isOwner: boolean;
};

const ActionItemModal = ({
  route,
  router,
  show,
  setTicketModal,
  setShowModal,
  actionItem,
  baseURL,
  resolveItem,
  isEditable,
  isOwner,
}: ModalProps): JSX.Element => {
  const [members, setMembers] = useState<Member[]>([]);
  const { show: showContextMenu } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  useEffect(() => {
    getMembers();
  }, []);

  const handleOpenTicketModal = () => {
    setTicketModal({ show: true, list: false });
  };

  const getMembers = async (): Promise<void> => {
    try {
      const retrievedMembers = await sendRequest('GET', `${baseURL}/memberships`, { showErrorAlert: false }, null);
      setMembers(retrievedMembers);
    } catch (e) {
      logger.logError('error_get_memberships', e);
    }
  };

  const Description = () => {
    return (
      <div className="description">
        {CONTENT(actionItem).map((contentItem, idx) => (
          <div key={idx} className="content-row">
            <h2>{contentItem.title}</h2>
            <div className="markdown">
              <ReactMarkdown>{contentItem.content}</ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Modal show={show} className="action-item-modal" size="lg" style={{ paddingTop: '4rem' }} keyboard={true}>
        <Modal.Header>
          <Modal.Title>
            <div className="close-title">
              <CloseIcon
                onClick={() => setShowModal(false)}
                className="description-panel__close"
                width="1rem"
                height="1rem"
              />
            </div>
            <div className="upper-title">
              <h1 className="action-item-modal-title">{actionItem?.Title}</h1>
              <div className="badges">
                <PillBadge severity={actionItem.Severity} />
                {actionItem?.Category && <PillBadge color="low" text={actionItem?.Category} />}
                <button
                  className="additional-actions-btn"
                  aria-haspopup
                  onClick={(e) => showContextMenu({ event: e, props: { actionItem } })}
                >
                  <img src={HorizontalEllipsis} alt="open action item menu" />
                </button>
              </div>
            </div>
            <div className="lower-title">
              <div className="first-seen">
                <h2 className="first-seen-title">First Seen</h2>
                <h3 className="first-seen-diff">{dateFromToday(actionItem.FirstSeen)}</h3>
              </div>
              <div className="last-seen">
                <h2 className="last-seen-title">Last Reported</h2>
                <h3 className="last-seen-diff">{dateFromToday(actionItem.LastReportedAt)}</h3>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-row">
            <Metadata actionItem={actionItem} />
          </div>
          <Description />
        </Modal.Body>
      </Modal>
      {show && (
        <ContextMenu
          members={members}
          baseURL={baseURL}
          toast={toast}
          router={router}
          resolveItem={resolveItem}
          handleOpenTicketModal={handleOpenTicketModal}
          setShowModal={setShowModal}
          route={route}
          isEditable={isEditable}
          isOwner={isOwner}
        />
      )}
    </>
  );
};

function Metadata({ actionItem }: { actionItem: ActionItem }) {
  return (
    <>
      {TOP_ROW(actionItem).map((headerItem, idx) => (
        <div key={idx} className={`${headerItem.title}`}>
          <h2 className="body-title">{headerItem.title}</h2>
          <h3 className="body-content">{headerItem.content || 'N/A'}</h3>
        </div>
      ))}
    </>
  );
}

export default ActionItemModal;
