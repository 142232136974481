import React, { useEffect, useState } from 'react';

import { Breadcrumbs } from '@fairwindsops/ui-components';
import HealthScoreCard from '~reactComponents/cards/healthScore/HealthScoreCard.react';
import TopIssues from '~reactComponents/cards/TopIssuesClusterOverview/TopIssuesClusterOverview.react';
import AdmissionClusterOverview from '~reactComponents/cards/AdmissionClusterOverview/AdmissionClusterOverview.react';
import ItemList from '~reactComponents/cards/ItemList/ItemList.react';
import IssuesThisWeek from '~reactComponents/cards/IssuesThisWeek/IssuesThisWeek.react';
import ClusterVersion from '~reactComponents/cards/clusterVersion/clusterVersion.react';
import LatestActivity from '~reactComponents/cards/LatestActivity/LatestActivity.react';
import Costs from '~reactComponents/cards/costs/Costs.react';
import HealthScoreTimeline from '~reactComponents/cards/healthScoreTimeline/HealthScoreTimeline.react';
import ActionItemsCard from '~reactComponents/cards/ActionItemsCard/ActionItemsCard.react';

import { ReportTypesForHealthScore as reportOptions, NO_NAMESPACE } from '~utils/constants';
import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';
import { handlePageChange } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import { IRoute, IRouter, IStore } from '~utils/global.types.react';
import { strings } from '~utils/strings';
import logger from '~utils/logger';

import './ReactOverview.react.scss';

type Member = {
  email: string;
  firstname: string;
  id: number;
  lastname: string;
  teams: [
    {
      id: number;
      name: string;
      role: string;
    },
  ];
};

type ReactOverviewProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const ReactOverview = ({ route, router, store }: ReactOverviewProps) => {
  const org = route?.params?.org;
  const cluster = route?.params?.cluster;
  const clusterSummary = store().getters.clusterSummary;
  const [namespaces, setNamespaces] = useState<string[]>([]);
  const [members, setMembers] = useState<Record<string, string>[]>([]);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'last',
      label: cluster,
      href: ``,
      isActive: true,
    },
  ];

  useEffect(() => {
    init();
    loadMembers();
  }, []);

  const init = async () => {
    const baseUrl = `/v0/organizations/${org}/clusters/${cluster}`;
    const requests = [
      sendRequest(
        'GET',
        `${baseUrl}/action-items/summary`,
        { showSuccessAlert: false, cache: true },
        null,
      ),
    ];
    let formattedNamespaces;

    try {
      const [actionItemSummary] = await Promise.allSettled(requests);
      formattedNamespaces = Object.keys(actionItemSummary?.value?.ByNamespace)?.map((namespace) =>
        namespace === '' ? NO_NAMESPACE : namespace,
      );
    } catch (e) {
      logger.logError('error_retrieving_overview_ai_summary', e);
    }
    setNamespaces(formattedNamespaces || []);
  };

  const loadMembers = async () => {
    const baseURL = `/v0/organizations/${org}/clusters/${cluster}`;
    let data;

    try {
      data = await sendRequest(
        'GET',
        `${baseURL}/access`,
        { showSuccessAlert: false, cache: true },
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_overview_members', e);
    }

    if (data?.access && data.access.length) {
      setMembers(
        data.access.map((datum: Member) => ({
          image: '',
          heading: datum.email,
          subheading: datum.teams.length ? datum.teams[0].role : '',
        })),
      );
    }
  };

  return (
    <div className="cluster--overview">
      <Breadcrumbs
        breadcrumbClassNames="cluster--overview--breadcrumbs"
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="cluster--overview--content">
        <div className="cluster--overview--content--left">
          <ActionItemsCard
            organization={org}
            cluster={cluster}
            namespaces={namespaces}
            reportOptions={reportOptions}
            router={router}
            className="action-items-card"
          />
          <HealthScoreTimeline
            organization={org}
            cluster={cluster}
            reportOptions={reportOptions}
            className="health-score-graph-card"
          />
          <TopIssues org={org} cluster={cluster} router={router} className="top-issues-card" />
          <IssuesThisWeek
            cluster={cluster}
            route={route}
            router={router}
            className="issues-this-week"
          />
          <Costs route={route} className="cost-card" />
          <AdmissionClusterOverview
            route={route}
            router={router}
            className="admission-cluster-overview"
          />
        </div>
        <div className="cluster--overview--content--right">
          <HealthScoreCard
            organization={org}
            cluster={cluster}
            isInstallAgentShown={true}
            router={router}
          />
          <ClusterVersion
            organization={org}
            cluster={cluster}
            clusterSummary={clusterSummary}
            router={router}
          />
          <LatestActivity store={store} />
          <ItemList title={strings.access} data={members} />
        </div>
      </div>
    </div>
  );
};

export default ReactOverview;
