import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { strings } from '~utils/strings';
import { colorScheme } from './main.config';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);

export const dateOptions = [
  {
    value: strings.noTranslate.monthly,
    label: strings.dateOptions.lastMonth,
  },
  {
    value: strings.noTranslate.weekly,
    label: strings.dateOptions.lastWeek,
  },
  {
    value: strings.noTranslate.hourly,
    label: strings.dateOptions.lastDay,
  },
];

export const timelineOptions = [
  { value: strings.noTranslate.fixed, label: strings.clusterOverview.fixedTimeline },
  { value: strings.noTranslate.new, label: strings.clusterOverview.newTimeline },
  { value: strings.noTranslate.resolved, label: strings.clusterOverview.resolvedTimeline },
  { value: 'critical', label: strings.clusterOverview.criticalTimeline },
  { value: 'high', label: strings.clusterOverview.highTimeline },
];

export const dataSummaryOptions = {
  newActionItems: strings.clusterOverview.newActionItems,
  fixedActionItems: strings.clusterOverview.fixedActionItems,
  resolvedActionItems: strings.clusterOverview.resolvedActionItems,
  highActionItems: strings.clusterOverview.highActionItems,
  criticalActionItems: strings.clusterOverview.criticalActionItems,
};

export const dataSummaryColors = {
  newActionItems: null,
  fixedActionItems: null,
  resolvedActionItems: colorScheme.none,
  dangerActionItems: colorScheme.critical,
  highActionItems: colorScheme.high,
  criticalActionItems: colorScheme.critical,
};

export const dataSummaryInit = {
  newActionItems: {
    count: 0,
    delta: 0,
  },
  fixedActionItems: {
    count: 0,
    delta: 0,
  },
  resolvedActionItems: {
    count: 0,
    delta: 0,
  },
  highActionItems: {
    count: 0,
    delta: 0,
  },
  criticalActionItems: {
    count: 0,
    delta: 0,
  },
};

const convertDate = (date) => {
  switch (date.value) {
    case strings.noTranslate.monthly:
      return strings.dateOptions.pastMonth;
    case strings.noTranslate.weekly:
      return strings.dateOptions.pastWeek;
    case strings.noTranslate.hourly:
      return strings.dateOptions.pastDay;
    default:
      break;
  }
  return strings.dateOptions.pastMonth;
};

export const dataSummaryGoTo = (cluster, date) => {
  const convertedDate = convertDate(date);
  return {
    newActionItems: {
      Cluster: cluster,
      FirstSeen: convertedDate,
      Fixed: ['false'],
      from: [strings.noTranslate.clusterOverview],
      LastReportedAt: convertedDate,
      Resolution: ['None'],
      sortBy: 'FirstSeen',
      sortDesc: 'true',
    },
    fixedActionItems: {
      Cluster: cluster,
      FirstSeen: convertedDate,
      Fixed: ['true'],
      from: [strings.noTranslate.clusterOverview],
      LastReportedAt: convertedDate,
      sortBy: 'LastSeen',
      sortDesc: 'true',
    },
    resolvedActionItems: {
      Cluster: cluster,
      FirstSeen: convertedDate,
      from: [strings.noTranslate.clusterOverview],
      LastReportedAt: convertedDate,
      Resolution: ['working_as_intended', 'wont_fix', 'snoozed'],
      sortBy: 'Severity',
      sortDesc: 'true',
    },
    highActionItems: {
      Cluster: cluster,
      FirstSeen: convertedDate,
      from: [strings.noTranslate.clusterOverview],
      LastReportedAt: convertedDate,
      Resolution: ['None'],
      Severity: ['high'],
      sortBy: 'Severity',
      sortDesc: 'true',
    },
    criticalActionItems: {
      Cluster: cluster,
      FirstSeen: convertedDate,
      from: [strings.noTranslate.clusterOverview],
      LastReportedAt: convertedDate,
      Resolution: ['None'],
      Severity: ['critical'],
      sortBy: 'Severity',
      sortDesc: 'true',
    },
  };
};
