import React from 'react';
import { IRoute, IRouter, IStore } from '~utils/global.types.react';
import { marked } from 'marked';

import './style.scss';

import admission from './reports/admission.md';
import goldilocks from './reports/goldilocks.md';
import kubehunter from './reports/kube-hunter.md';
import nova from './reports/nova.md';
import polaris from './reports/polaris.md';
import rbacReporter from './reports/rbac-reporter.md';
import kubebench from './reports/kube-bench.md';
import trivy from './reports/trivy.md';
import opa from './reports/opa.md';
import pluto from './reports/pluto.md';
import workloads from './reports/workloads.md';
import resourceMetrics from './reports/resource-metrics.md';
import falco from './reports/falco.md';
import awscosts from './reports/awscosts.md';
import rightsizer from './reports/right-sizer.md';
import kyverno from './reports/kyverno.md';

export const REPORT_INTROS: Record<string, string> = {
  admission,
  polaris,
  workloads,
  'rbac-reporter': rbacReporter,
  'kube-bench': kubebench,
  trivy,
  opa,
  goldilocks,
  pluto,
  nova,
  'kube-hunter': kubehunter,
  'prometheus-metrics': resourceMetrics,
  falco,
  awscosts,
  kyverno,
  'right-sizer': rightsizer,
};

interface AboutReportProps {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
}

export default function ReportHubAbout({ route }: AboutReportProps) {
  const { report, org, cluster } = route?.params || {};

  let markdownText = REPORT_INTROS[report];
  const baseRoute = `/orgs/${org}/clusters/${cluster}`;
  markdownText = markdownText.replace('$BASE_ROUTE', baseRoute);
  const markdown = marked(markdownText);

  return (
    <>
      <div className="report-hub-about" dangerouslySetInnerHTML={{ __html: markdown }}></div>
    </>
  );
}
