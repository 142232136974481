import * as React from 'react';

type IconProps = {
  isFlipped?: boolean;
  paddingRight?: string;
  fill: string;
};

const CaretUpIcon = ({ isFlipped, ...props }: IconProps): JSX.Element => (
  <svg
    style={{
      transform: isFlipped ? 'rotate(180deg)' : '',
      marginTop: isFlipped ? '0.25rem' : '',
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
  </svg>
);

export default CaretUpIcon;
