import React, { useEffect, useState, useRef, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Select from 'react-select';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import AdmissionChart from '~views/organization/admissionRequests/components/AdmissionChart/AdmissionChart.react';
import { Card } from '@fairwindsops/ui-components';

import {
  OptionType,
  RequestCountObjectType,
  RequestCountType,
} from '~views/organization/admissionRequests/ReactAdmission.types.react';
import { defaultDateTimeOptions } from './AdmissionClusterOverview.react.types.react';
import { IRouter, IRoute } from '~globalTypes';

import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import logger from '~logger';
import { getCurrentTimezone } from '~reactHelpers';

import './AdmissionClusterOverview.react.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const todayTz = getCurrentTimezone();
const today = todayTz ? dayjs().tz(todayTz) : dayjs();
const dayAgo = today.clone().subtract(1, 'day');

const formatDatetime = (m: Dayjs): string => {
  return m.toISOString();
};

type AdmissionClusterOverviewProps = {
  route: IRoute;
  router: () => IRouter;
  className?: string;
};

const AdmissionClusterOverview = ({ route, router, className }: AdmissionClusterOverviewProps): JSX.Element => {
  const org = route?.params?.org;
  const cluster = route?.params?.cluster;
  const baseURL = `/v0/organizations/${org}/clusters/${cluster}`;
  const [requestCount, setRequestCount] = useState<RequestCountType>({ interval: 'hour', requestCount: [] });
  const [admissionRequestExists, setAdmissionRequestExists] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isMounted = useRef<boolean>(false);
  const isFirstTimeLoaded = useRef<boolean>(true);

  const [dateTimeOption, setDateTimeOption] = useState<OptionType | null>(
    defaultDateTimeOptions.find((option: OptionType) => option.value === strings.dateOptions.pastWeek) || null,
  );

  const timeZone = getCurrentTimezone();

  const startTime = useMemo(() => {
    if (!dateTimeOption) {
      return formatDatetime(dayAgo);
    }
    switch (dateTimeOption.value) {
      case strings.dateOptions.pastDay:
        return formatDatetime(dayAgo);
      case strings.dateOptions.pastWeek:
        return formatDatetime(today.clone().subtract(1, 'week'));
      case strings.dateOptions.pastMonth:
        return formatDatetime(today.clone().subtract(1, 'month'));
      default:
        return formatDatetime(dayAgo);
    }
  }, [dateTimeOption]);

  const endTime = formatDatetime(today);

  useEffect(() => {
    logger.logEvent('admission-cluster-overview:page-load');
    if (!isMounted.current && isFirstTimeLoaded) {
      init();
      isMounted.current = true;
      isFirstTimeLoaded.current = false;
    }
    return () => {
      isMounted.current = false;
      isFirstTimeLoaded.current = true;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && !isFirstTimeLoaded.current) {
      init();
    }
  }, [route?.params?.cluster, dateTimeOption]);

  const checkAdmissionRequestExists = async (): Promise<boolean> => {
    let response = false;
    try {
      response = await sendRequest(
        'GET',
        `${baseURL}/admission-requests/exists?startTime=${startTime}&endTime=${endTime}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError(strings.clusterOverview.admissionRequestsExistsErrorMsg, e);
    }
    return response;
  };

  const init = async () => {
    const admissionRequestsExists = await checkAdmissionRequestExists();
    setAdmissionRequestExists(admissionRequestsExists);
    setLoading(true);
    if (admissionRequestsExists) {
      const { requestCount, interval } = await getAdmissionRequestsCount();
      setRequestCount({ requestCount, interval });
    }
    setLoading(false);
  };

  const getAdmissionRequestsCount = async (
    start = startTime,
    end = endTime,
  ): Promise<{ requestCount: RequestCountObjectType[]; interval: 'day' | 'hour' }> => {
    const difference = timeZone ? dayjs(end).tz(timeZone).diff(start, 'day') : dayjs(end).diff(start, 'day');
    const interval = difference > 2 ? 'day' : 'hour';
    let response;
    try {
      response = await sendRequest(
        'GET',
        `${baseURL}/admission-requests/count?startTime=${start}&endTime=${end}&interval=${interval}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError(strings.clusterOverview.admissionRequestsCountErrorMsg, e);
    }
    return { requestCount: response, interval };
  };

  return (
    <>
      <Card className={`${className} admission-cluster-overview__card`}>
        <Card.Header style={{ padding: '0 1rem', borderBottom: COLORS.BORDER.TABLE_HEADER }}>
          <h2>{strings.clusterOverview.admissionRequests}</h2>
          <div className="admission-cluster-overview__date-select-container">
            <Select
              aria-label="Top Issues Date Select"
              className="admission-cluster-overview__date-select"
              classNamePrefix="admission-cluster-overview__date-select"
              isSearchable={false}
              options={defaultDateTimeOptions}
              value={dateTimeOption}
              onChange={(selected) => setDateTimeOption(selected)}
            />
          </div>
        </Card.Header>
        <Card.Body className={loading ? 'admission-chart-card-spinner' : ''}>
          {requestCount?.requestCount?.length ? (
            <AdmissionChart
              interval={requestCount.interval}
              data={requestCount.requestCount}
              chart={'number'}
              admissionRequestExists={admissionRequestExists}
              router={router}
              loading={loading}
            />
          ) : (
            <div className="empty-container">
              <div className="empty-text">
                No data available.{' '}
                <a
                  href="report-hub/report/admission"
                  onClick={(e) => {
                    e.preventDefault();
                    router().push({ path: 'report-hub/report/admission' });
                  }}
                >
                  Install the admission controller
                </a>{' '}
                to see results.
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default AdmissionClusterOverview;
