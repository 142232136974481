import dayjs from 'dayjs';
import { IReportEventSet } from '~utils/global.types.react';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import '../style.scss';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export function formatTime(timestamp: string, timeZone: string) {
  if (!timestamp) {
    return 'testing';
  }
  return timeZone ? dayjs(timestamp).tz(timeZone).format('lll') : dayjs(timestamp).format('lll');
}

export function formatTimeAgo(timestamp: string, timeZone: string) {
  return timeZone
    ? dayjs().tz(timeZone).to(dayjs(timestamp).tz(timeZone))
    : dayjs().to(dayjs(timestamp));
}

export function formatTimestampToDate(timestamp: string, timeZone: string) {
  return timeZone
    ? dayjs(timestamp).utc().tz(timeZone).format('YYYY-MM-DD')
    : dayjs(timestamp).utc().format('YYYY-MM-DD');
}

export function formatTimestamp(timestamp: string, timeZone: string) {
  return timeZone
    ? dayjs(timestamp).utc().tz(timeZone).format('YYYY-MM-DDTHH:mm:ss.SSS')
    : dayjs(timestamp).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
}

export function formatTimestampWithDuplicates(eventSet: IReportEventSet, timeZone: string) {
  if (eventSet.Duplicates && eventSet.Duplicates.length) {
    return formatTimestamp(eventSet.Duplicates[0], timeZone);
  }
  return formatTimestamp(eventSet.Timestamp, timeZone);
}
