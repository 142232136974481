import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

export const colors = [
  COLORS.CHARTS.CAPACITY[100],
  COLORS.CHARTS.CAPACITY[110],
  COLORS.CHARTS.CAPACITY[120],
  COLORS.CHARTS.CAPACITY[130],
  COLORS.CHARTS.CAPACITY[140],
  COLORS.CHARTS.CAPACITY[150],
  COLORS.CHARTS.CAPACITY[160],
  COLORS.CHARTS.CAPACITY[170],
  COLORS.CHARTS.CAPACITY[180],
  COLORS.CHARTS.CAPACITY[190],
  COLORS.CHARTS.CAPACITY[200],
  COLORS.CHARTS.CAPACITY[210],
  COLORS.CHARTS.CAPACITY[220],
  COLORS.CHARTS.CAPACITY[230],
  COLORS.CHARTS.CAPACITY[240],
  COLORS.CHARTS.CAPACITY[250],
  COLORS.CHARTS.CAPACITY[260],
  COLORS.CHARTS.CAPACITY[270],
  COLORS.CHARTS.CAPACITY[280],
  COLORS.CHARTS.CAPACITY[290],
  COLORS.CHARTS.CAPACITY[300],
];

export const dropdownOptions = [
  { value: 'cpu', label: strings.general.CPU },
  { value: 'memory', label: strings.general.Memory },
];

export const timeperiodOptions = [
  { value: 'current', label: strings.efficiency.dailyAverage },
  { value: 'average', label: strings.general.thirtyDayAvg },
];

export const costDropdownOptions = [
  { value: 'hour', label: strings.dateOptions.lastDay },
  { value: 'week', label: strings.dateOptions.lastWeek },
  { value: 'month', label: strings.dateOptions.lastMonth },
];

export const chartSettingsByNodeCount = [
  {
    minCount: 1,
    maxCount: 301,
    height: 250,
    multiplier: 2.2,
  },
  {
    minCount: 301,
    maxCount: 801,
    height: 250,
    multiplier: 2.1,
  },
  {
    minCount: 801,
    maxCount: 2001,
    height: 500,
    multiplier: 1.45,
  },
  {
    minCount: 2001,
    maxCount: 3001,
    height: 700,
    multiplier: 1.15,
  },
  {
    minCount: 3001,
    maxCount: 4501,
    height: 900,
    multiplier: 1.05,
  },
  {
    minCount: 4501,
    maxCount: 5000,
    height: 1100,
    multiplier: 0.95,
  },
];
