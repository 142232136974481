import React from 'react';
import clsx from 'clsx';

import { strings } from '~utils/strings';
import { XSText } from '~utils/texts.react';

import './LastSyncedDate.react.scss';

type LastSyncedDateProps = {
  className?: string;
  hasData?: boolean;
  node?: React.ReactNode;
  noneSyncedDateTitle?: string | React.ReactNode;
  noneSyncedCircleClassname?: string;
  syncedDate?: string;
  syncedDateTitle?: string;
  loading?: boolean;
  givenCircleColor?: string;
};

enum SyncedCircleColor {
  Success = 'green',
  Warning = 'yellow',
  Error = 'red',
}

const LastSyncedDate = ({
  className,
  hasData,
  node,
  noneSyncedDateTitle,
  noneSyncedCircleClassname,
  syncedDate,
  syncedDateTitle,
  loading,
  givenCircleColor,
}: LastSyncedDateProps) => {
  let circleColor = SyncedCircleColor.Warning;

  if (!loading && noneSyncedDateTitle) {
    circleColor = SyncedCircleColor.Error;
  }

  if (syncedDate || hasData) {
    return (
      <div className={`custom-last-synced-date__synced-div-section ${className || ''}`}>
        <div
          className={clsx(
            'custom-last-synced-date__synced-circle',
            `custom-last-synced-date__synced-green-circle`,
            `custom-last-synced-date__synced-${givenCircleColor || 'green'}-circle`,
          )}
        ></div>
        <span
          className={clsx(
            'custom-last-synced-date__synced-bold',
            XSText({ color: strings.textStyling.primary, weight: strings.textStyling.medium }),
          )}
        >
          {syncedDateTitle}
        </span>
        {syncedDate}
        {node}
      </div>
    );
  }

  return (
    <div className={`custom-last-synced-date__synced-div-section ${className || ''}`}>
      <div
        className={clsx(
          `custom-last-synced-date__synced-circle`,
          `custom-last-synced-date__synced-${circleColor}-circle`,
          noneSyncedCircleClassname,
        )}
      ></div>
      {noneSyncedDateTitle}
      {node}
    </div>
  );
};

export default LastSyncedDate;
