import React, { useEffect, useMemo, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import {
  ORG_DASHBOARD,
  CLUSTER_OVERVIEW,
  CLUSTER_REPORT_HUB,
  CLUSTERS,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { UPDATE_CLUSTER } from '~store/action.types';

import { ConfigReportOptions, IRoute, IRouter, IStore } from '~utils/global.types.react';

import { handlePageChange } from '~utils/global.helpers.react';
import { copyToClipboard } from '~utils/helpers';
import { DefaultServerHost, REPORTS_DETAILS, getAllImages } from '~utils/constants';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';

import './style.scss';

const INTERVAL = 2500;
const ONE_HOUR_AT_INTERVAL = 3_600_000 / INTERVAL;

interface InstallProps {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
}

export default function Install({ route, router, store }: InstallProps) {
  const {
    isOrgOwner,
    organization,
    cluster,
    clusterSummary,
    reportsConfigs,
    installingReports,
    uninstallingReports,
  } = store().getters as any;
  const { org } = route.params || {};

  const hostName = window.location.origin;

  const [clusterObject, setClusterObject] = useState<Record<string, any>>({});

  const orgBaseURL = `/v0/organizations/${organization.Name}`;
  const baseURL = `${orgBaseURL}/clusters/${cluster.Name}`;
  const setup = route?.query?.setup || false;

  useEffect(() => {
    let updateInterval = -1;
    let count = 0;

    if (setup && isOrgOwner) {
      updateInterval = setInterval(() => {
        checkIfReady();
      }, INTERVAL) as unknown as number;
    }

    async function checkIfReady() {
      count++;
      if (count > ONE_HOUR_AT_INTERVAL) {
        clearInterval(updateInterval);
        return;
      }
      const url = `${baseURL}/isReady`;
      let ready;

      try {
        ready = await sendRequest('GET', url, undefined, undefined);
      } catch (e) {
        toast.error('An error occurred while polling for the Agent, please refresh the page.');
        clearInterval(updateInterval);
      }

      if (ready) {
        await store().dispatch(UPDATE_CLUSTER, cluster.Name);

        router().push({
          name: CLUSTER_OVERVIEW,
          params: { org: organization.Name, cluster: cluster.Name },
        });
      }
    }

    if (isOrgOwner && cluster.Name) {
      sendRequest('GET', `${baseURL}?showToken=true`, undefined, undefined).then(
        (data: Record<string, any>) => {
          setClusterObject(data);
        },
      );
    }

    return () => {
      clearInterval(updateInterval);
    };
  }, [cluster, isOrgOwner, setup]);

  const unpinnedChart = useMemo(() => {
    if (!clusterSummary) {
      return '';
    }
    // eslint-disable-next-line prefer-regex-literals
    return clusterSummary.AgentChartTargetVersion.replace(new RegExp('\\.\\d*$'), '.*');
  }, []);

  const helmCommand = useMemo(() => {
    let command = '';
    if (!setup) {
      command += 'kubectl delete job --all --namespace insights-agent\n';
      command += 'helm repo update\n';
    } else {
      command += 'helm repo add fairwinds-stable https://charts.fairwinds.com/stable\n';
    }
    if (reportsConfigs.trivy && reportsConfigs.trivy.Options.privateImages) {
      command += '\n# Create a dockerconfig to let Trivy pull private images, e.g.\n';
      command +=
        'kubectl create secret generic insights-pull --from-file=config.json=$HOME/.docker/config.json -n insights-agent\n\n';
    }
    command +=
      'helm upgrade --install insights-agent fairwinds-stable/insights-agent -f values.yaml \\\n';
    command += `  --version "${unpinnedChart}" \\\n`;
    command += '  --create-namespace \\\n';
    command += '  --namespace insights-agent \\\n';
    command += '  --wait \\\n';
    command += '  --atomic';
    return command;
  }, [reportsConfigs, setup, unpinnedChart]);

  const base64Token = useMemo(() => {
    if (!clusterObject || !clusterObject.AuthToken) {
      return '';
    }
    return btoa(clusterObject.AuthToken);
  }, [clusterObject]);

  const valuesYaml = useMemo(() => {
    let values = '';
    values += 'insights:\n';
    values += `  organization: "${organization.Name}"\n`;
    values += `  cluster: "${cluster.Name}"\n`;
    values += `  base64token: "${base64Token}"\n`;
    if (hostName !== DefaultServerHost) {
      values += `  host: "${hostName}"\n`;
    }

    if (reportsConfigs.goldilocks && reportsConfigs.goldilocks.Options.installVPA) {
      values += 'goldilocks:\n';
      values += '  reinstallVPA: true\n';
    }
    const reportTypes = Object.keys(reportsConfigs);
    reportTypes.forEach((reportType) => {
      const settings = reportsConfigs[reportType];
      let isReportNameSet = false;
      if (settings) {
        values += `${reportType}:\n`;
        isReportNameSet = true;
        values += '  enabled: true\n';
        if (reportType === 'prometheus-metrics') {
          values += '  installPrometheusServer: true\n';
        }
      }
      Object.keys(settings.Options).forEach((option) => {
        const optionKey = option as keyof ConfigReportOptions;
        if (
          option !== 'enabled' &&
          option !== 'defaultToOrg' &&
          settings.Options[optionKey] !== null
        ) {
          if (!isReportNameSet) {
            values += `${reportType}:\n`;
            isReportNameSet = true;
          }
          values += `  ${optionKey}: "${settings.Options[optionKey]}"\n`;
        }
      });
    });
    return values;
  }, [hostName, cluster, organization, base64Token]);

  function getImage(name: string) {
    const images = getAllImages();
    return images[name] || images.default;
  }
  function copyToClipboardWithToast(text: string) {
    copyToClipboard(text);
    toast.success('Copied to Clipboard');
  }

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTERS,
      label: strings.navigation.allClusters,
      href: `/orgs/${org}/clusters`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster.Name,
      href: `/orgs/${org}/clusters/${cluster.Name}/overview`,
    },
    {
      id: CLUSTER_REPORT_HUB,
      label: 'Install Hub',
      href: `/orgs/${org}/clusters/${cluster.Name}/report-hub`,
    },
    {
      id: 'last',
      label: 'Install',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <section>
        <h4 className="mb-2">Install with Helm</h4>
        {!isOrgOwner && (
          <div className="alert alert-warning">
            <span>
              You're not an owner for {organization.Name}. You'll need to ask an owner to change
              cluster settings to be able to install.
            </span>
          </div>
        )}

        {isOrgOwner && (
          <div>
            <div>
              To install the Insights Agent, create a{' '}
              <code style={{ display: 'inline' }}>values.yaml </code>file. You'll probably want to
              store this in a Git repository.
            </div>
            <p>
              The values below are suggested based on the choices you've made here in the Insights
              UI. If you want to further customize your Agent, see the{' '}
              <a
                href="https://github.com/FairwindsOps/charts/blob/master/stable/insights-agent/README.md"
                target="_blank"
              >
                full documentation.
              </a>
            </p>
            <div className="d-flex">
              <div>
                <button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => copyToClipboardWithToast(valuesYaml)}
                >
                  <i className="fa fa-copy" aria-hidden="true"></i>
                </button>
              </div>
              <div className="pre_code">
                <pre>
                  <code>{valuesYaml}</code>
                </pre>
              </div>
            </div>
            <div className="instruction">Next, install the Helm chart with these values:</div>
            <div className="d-flex">
              <div>
                <button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => copyToClipboardWithToast(helmCommand)}
                >
                  <i className="fa fa-copy" aria-hidden="true"></i>
                </button>
              </div>
              <div className="pre_code">
                <pre>
                  <code>{helmCommand}</code>
                </pre>
              </div>
            </div>
          </div>
        )}
      </section>
      {installingReports.length > 0 && (
        <section>
          <h3 className="font-weight-bold">Installing Reports</h3>
          <div className="card-list">
            <div className="row">
              {installingReports.map((installedReport) => {
                const installedReportKey = installedReport as keyof typeof REPORTS_DETAILS;
                const installedReportDetails = REPORTS_DETAILS[installedReportKey];
                return (
                  <ReportCard
                    installedReportDetails={installedReportDetails}
                    imageSrc={getImage(installedReport)}
                  />
                );
              })}
            </div>
          </div>
        </section>
      )}
      {uninstallingReports.length > 0 && (
        <section>
          <h3 className="font-weight-bold">Removing Reports</h3>
          <div className="card-list">
            <div className="row">
              {uninstallingReports.map((uninstalledReport) => {
                const uninstalledReportKey = uninstalledReport as keyof typeof REPORTS_DETAILS;
                const uninstalledReportDetails = REPORTS_DETAILS[uninstalledReportKey];
                return (
                  <ReportCard
                    installedReportDetails={uninstalledReportDetails}
                    imageSrc={getImage(uninstalledReport)}
                  />
                );
              })}
            </div>
          </div>
        </section>
      )}
      <Toaster />
    </LayoutReact>
  );
}

interface InstallCardProps {
  installedReportDetails: any;
  imageSrc: string;
}

function ReportCard({ installedReportDetails, imageSrc }: InstallCardProps) {
  return (
    <div className="text-center integration-card">
      <div className="card mini-card installing-background h-100">
        <div className="card-body mixin">
          <div className="report-image">
            <img src={imageSrc} alt="report icon" />
          </div>
          <h3 className="card-title mb-1">
            <span>{installedReportDetails.name}</span>
          </h3>
          <p className="card-text">{installedReportDetails.description}</p>
        </div>
      </div>
    </div>
  );
}
