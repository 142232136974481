import React, { useMemo, useState, useEffect } from 'react';
import Select from 'react-select';
import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';
import LineChart from '~reactComponents/charts/LineChart/LineChart.react';

import { Cluster, OptionType, Organization } from '~utils/global.types.react';
import { getCurrentTimezone } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import logger from '~utils/logger';
import { XLBasicText } from '~utils/texts.react';

import './HealthScoreTimeline.react.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

type HealthScoreTimelineProps = {
  organization: Organization;
  cluster: Cluster;
  reportOptions: string[];
  className?: string;
};

const HealthScoreTimeline = ({
  organization,
  cluster,
  reportOptions,
  className,
}: HealthScoreTimelineProps) => {
  const dateTimeOptions: OptionType[] = [
    { value: strings.noTranslate.hourly, label: strings.dateOptions.lastDay },
    { value: strings.noTranslate.weekly, label: strings.dateOptions.lastWeek },
    { value: strings.noTranslate.monthly, label: strings.dateOptions.lastMonth },
  ];
  const timeZone = getCurrentTimezone();
  const [selectedDateFilter, setSelectedDateFilter] = useState<OptionType>({
    value: strings.noTranslate.monthly,
    label: strings.dateOptions.lastMonth,
  });
  const [selectedReport, setSelectedReport] = useState<OptionType>({
    value: '',
    label: strings.clusterOverview.allReports,
  });
  const [data, setData] = useState<Record<string, number>>({});

  const reportsToDisplay = useMemo(() => {
    const allOptions = reportOptions.map((option) => ({ value: option, label: option }));
    allOptions.unshift({
      value: '',
      label: strings.clusterOverview.allReports,
    });
    return allOptions;
  }, [reportOptions]);

  useEffect(() => {
    const getScores = async () => {
      let response = {};
      try {
        response = await sendRequest(
          'GET',
          healthScoreURL,
          { showSuccessAlert: false, cache: true },
          null,
        );
      } catch (e) {
        logger.logError('error_retrieving_historical_health_score', e);
      }
      setData(response.Scores);
    };

    getScores();
  }, [selectedDateFilter, selectedReport]);

  const formattedData = useMemo(() => {
    if (data) {
      const newData = Object.keys(data).map((date, index) => {
        return {
          x: new Date(Date.parse(date)),
          y: data[date],
        };
      });
      return [{ id: 'score', data: newData }];
    } else {
      return [];
    }
  }, [data]);

  const healthScoreURL = useMemo(() => {
    let startDate;
    let reportName = '';
    let dateFilter;
    const endDate = new Date().toISOString();
    switch (selectedDateFilter.value) {
      case strings.noTranslate.hourly:
        dateFilter = strings.noTranslate.hourly;
        startDate = timeZone
          ? dayjs()
              .subtract(23, strings.noTranslate.hours as ManipulateType)
              .subtract(59, strings.noTranslate.minutes as ManipulateType)
              .utc()
              .tz(timeZone)
              .toISOString()
          : dayjs()
              .subtract(23, strings.noTranslate.hours as ManipulateType)
              .subtract(59, strings.noTranslate.minutes as ManipulateType)
              .utc()
              .toISOString();
        break;
      case strings.noTranslate.weekly:
        dateFilter = strings.noTranslate.daily;
        startDate = timeZone
          ? dayjs().subtract(1, 'week').utc().tz(timeZone).toISOString()
          : dayjs().subtract(1, 'week').utc().toISOString();
        break;
      case strings.noTranslate.monthly:
        dateFilter = strings.noTranslate.daily;
        startDate = timeZone
          ? dayjs().subtract(1, 'month').utc().tz(timeZone).toISOString()
          : dayjs().subtract(1, 'month').utc().toISOString();
        break;
      default:
        break;
    }
    if (selectedReport.value) reportName = `&reportType=${selectedReport.value}`;
    return `/v0/organizations/${organization}/clusters/${cluster}/health-score/historical?window=${dateFilter}&startTime=${startDate}&endTime=${endDate}${reportName}`;
  }, [selectedDateFilter, selectedReport]);

  const tooltip = (point: any) => {
    return (
      <div className="health-score-timeline__tooltip">
        <strong>{point.data.xFormatted}</strong>
        <div>
          <span className="tooltip-title">{`${strings.healthScore}:`}</span>
          <span>{` ${Math.round(point.data.y)}%`}</span>
        </div>
      </div>
    );
  };

  const lineChartProps = {
    data: formattedData,
    margin: { top: 25, right: 50, bottom: 55, left: 60 },
    xScale: {
      type: 'time',
      format: selectedDateFilter.value === strings.noTranslate.hourly ? '%Y-%m-%d %H' : '%Y-%m-%d',
      useUTC: false,
      precision: selectedDateFilter.value === strings.noTranslate.hourly ? 'hour' : 'day',
    },
    xFormat: selectedDateFilter.value === strings.noTranslate.hourly ? 'time:%H' : 'time:%Y-%m-%d',
    yScale: {
      type: 'linear',
      min: 0,
      max: 100,
      stacked: false,
      reverse: false,
    },
    colors: COLORS.CHARTS.LINE.GOOD,
    dotColor: COLORS.CHARTS.LINE.GOOD,
    axisBottom: {
      format: (value: Date) => {
        const formatter = timeZone ? dayjs(value).tz(timeZone) : dayjs(value);
        return selectedDateFilter.value === strings.noTranslate.hourly
          ? formatter.format('M/D hA')
          : formatter.format('M/D');
      },
      tickValues:
        selectedDateFilter.value === strings.noTranslate.hourly
          ? strings.noTranslate.every4Hours
          : 'every 1 days',
      tickRotation: -45,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -45,
      format: (value: number) => `${value}%`,
    },
    tooltip: tooltip,
  };

  return (
    <Card className={className} data-cy="overview-healthScoreTimeline-card">
      <Card.Header className="health-score-timeline__header">
        <h2 className={clsx('health-score-timeline__title', XLBasicText())}>
          {strings.general.healthScore}
        </h2>
        <div className="health-score-timeline__dropdowns-container">
          <div
            className="health-score-timeline date-select-container"
            data-cy="overview-healthscoreTimeline-card__reports"
          >
            <Select
              aria-label={strings.ariaLabels.healthScoreTimelineReportSelect}
              className="health-score-timeline date-select"
              classNamePrefix="health-score-timeline date-select"
              isSearchable={false}
              options={reportsToDisplay}
              value={selectedReport}
              onChange={setSelectedReport}
            />
          </div>
          <div
            className="health-score-timeline date-select-container"
            data-cy="overview-healthscoreTimeline-card__dates"
          >
            <Select
              aria-label={strings.ariaLabels.healthScoreTimelinePeriodSelect}
              className="health-score-timeline date-select"
              classNamePrefix="health-score-timeline date-select"
              isSearchable={false}
              options={dateTimeOptions}
              value={selectedDateFilter}
              onChange={setSelectedDateFilter}
            />
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div
          className="health-score-timeline__chart"
          aria-label={strings.ariaLabels.healthScoreTimelineChart}
        >
          {formattedData?.length > 0 && <LineChart {...lineChartProps} />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default HealthScoreTimeline;
