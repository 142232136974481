import React, { useState, useEffect, useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import ReportIntegrationCard from '~reactComponents/Report-Integration/Report-IntegrationCard.react';

import { REMOVE_REPORT_CONFIGS, UPDATE_REPORTS_CONFIGS } from '~store/action.types';
import { getAllImages, REPORTS_DETAILS } from '~utils/constants';
import { strings } from '~utils/strings';
import { hasKey, handlePageChange } from '~reactHelpers';

import dayjs from 'dayjs';
import toast, { Toaster } from 'react-hot-toast';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import Icon from '~reactComponents/Icons/Icon.react';
import ReportIcon from '~public/icons/report.svg';

import { UNCLICKABLE_REPORTS } from './Overview.types.react';
import { ConfigReport, ReportsStatus, IStore, IRouter, IRoute } from '~globalTypes';

import {
  CLUSTER_OVERVIEW,
  ORG_DASHBOARD,
  CLUSTERS,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { COLORS } from '~utils/styling';

import './Overview.react.scss';

dayjs.extend(relativeTime);

type OverviewProps = {
  store: () => IStore;
  router: () => IRouter;
  route: IRoute;
};

type ReportsDetailsType = {
  name: string;
  id: string;
  description: string;
  required?: boolean;
  link?: string;
};

const OverviewPage = ({ router, route, store }: OverviewProps) => {
  const org = route?.params?.org;
  const cluster = route?.params?.cluster;
  const isOrgOwner = store().getters.isOrgOwner;
  const sections = ['installed', 'uninstalled', 'available'];
  const reportsDetails: Record<string, ReportsDetailsType> = REPORTS_DETAILS;
  const [images, setImages] = useState<Record<string, string> | null>(null);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    setImages(getAllImages());
    updateConfigs();
    setCounter((prevNum) => prevNum + 1);
  }, [cluster]);

  const getSectionByName = (section: string) => {
    switch (section) {
      case 'installed':
        return store()?.getters?.installedReports;
      case 'uninstalled':
        return store()?.getters?.uninstalledReports;
      case 'available':
        return store().getters.neverInstalledReports;
      default:
        [];
    }
  };

  const getImage = (name: string) => {
    if (!images) return;
    return hasKey(images, name) ? images?.[name] : images?.[strings.noTranslate.default];
  };

  const addReport = async (report: string) => {
    await store().dispatch(UPDATE_REPORTS_CONFIGS, { report: report });
    updateConfigs();
    setCounter((prevNum) => prevNum + 1);
    toast.success(
      <b className="install-hub__toast-message">{`${strings.general.report} ${strings.added}${strings.punctuation.exclamation}`}</b>,
    );
  };

  const removeReport = async (report: string) => {
    await store().dispatch(REMOVE_REPORT_CONFIGS, report);
    updateConfigs();
    setCounter((prevNum) => prevNum + 1);
    toast.success(
      <b className="install-hub__toast-message">{`${strings.general.report} ${strings.removed}${strings.punctuation.exclamation}`}</b>,
    );
  };

  const checkStatus = (section: string, configs: ConfigReport | string) => {
    if (section === 'installed') {
      return 'installed';
    } else if (configs) {
      return 'pending';
    }
    return 'available';
  };

  const goToReport = (report: string) => {
    if (UNCLICKABLE_REPORTS.includes(report)) {
      return;
    }
    router().push({ name: 'hub-report-configure', params: { report: report } });
  };

  const updateConfigs = () => ({
    configs: store().getters.reportsConfigs as Record<string, ConfigReport>,
    statuses: store().getters.reportsStatus as Record<string, ReportsStatus>,
  });

  const ReportsToDisplay = useMemo(() => {
    const { configs, statuses } = updateConfigs();
    const allSections = sections.map((section) => {
      const retrievedSection = getSectionByName(section);

      if (retrievedSection && retrievedSection?.length) {
        return (
          <>
            <div className="install-hub__section-title-wrapper">
              <h2 className="install-hub__section-title">{section}</h2>
              {isOrgOwner &&
              section === 'installed' &&
              ((getSectionByName('installed') || '').length > 0 ||
                (getSectionByName('available') || '').length > 0) ? (
                <div
                  className="install-agent-btn install-agent-btn__setup"
                  onClick={() => handlePageChange(router, 'clusterhub-install')}
                >
                  <img src={ReportIcon} alt="install Agent" />
                  <p className="install-agent-btn__subtitle" data-cy="install-agent-button">
                    {strings.hubOverview.readyToInstall}
                  </p>
                </div>
              ) : (
                <div
                  className="install-agent-btn"
                  onClick={() => handlePageChange(router, 'clusterhub-install')}
                  data-cy="install-agent-button"
                >
                  <img src={ReportIcon} alt="report icon for agent install" />
                </div>
              )}
            </div>
            <div className="install-hub__section">
              {retrievedSection.map((report: string) => {
                if (hasKey(reportsDetails, report)) {
                  const config: ConfigReport | string = configs[report] || '';
                  return (
                    <ReportIntegrationCard
                      className="overview-integration-card"
                      key={report}
                      title={report}
                      name={reportsDetails[report].name}
                      description={reportsDetails[report].description}
                      status={checkStatus(section, config)}
                      isOrgOwner={isOrgOwner}
                      connectIntegration={addReport}
                      disconnectIntegration={removeReport}
                      image={getImage(report)}
                      time={
                        hasKey(statuses, report) && hasKey(statuses[report], 'LastSeen')
                          ? statuses[report].LastSeen || ''
                          : ''
                      }
                      openReport={goToReport}
                      reportConfig={store().getters.reportsConfigs[report]}
                      link={reportsDetails[report].link}
                    />
                  );
                }
              })}
            </div>
          </>
        );
      }
    });
    return <>{allSections}</>;
  }, [counter, cluster]);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTERS,
      label: strings.navigation.allClusters,
      href: `/orgs/${org}/clusters`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster,
      href: `/orgs/${org}/clusters/${cluster}`,
    },
    {
      id: 'last',
      label: strings.navigation.installHub,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="install-hub">
        {!isOrgOwner && (
          <Alert variant="warning" className="warning-alert">
            <Icon name="exclamation-triangle" width="1.5rem" fill={COLORS.CORE.WARNING} />
            {strings.hubOverview.nonOwner}
            <strong className="alert-read-only">{strings.general.pageReadOnly}</strong>
          </Alert>
        )}
        {isOrgOwner && (
          <div className="install-hub__instructions">
            To learn more about In-Cluster Scanning,{' '}
            <a
              className=""
              href="https://insights.docs.fairwinds.com/features/in-cluster-scanning/#about"
              target="_blank"
            >
              read the documentation
            </a>
            .
          </div>
        )}
        {ReportsToDisplay}
      </div>
      <Toaster />
    </LayoutReact>
  );
};

export default OverviewPage;
